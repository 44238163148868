import { useState } from "react";

export const useLinkManager = () => {
  const [linkConsultoria, setLinkConsultoria] = useState("");
  const [linkCupom, setLinkCupom] = useState("");
  const [linkCupomAndIndication, setLinkCupomAndIndication] = useState("");

  return {
    linkConsultoria,
    setLinkConsultoria,
    linkCupom,
    setLinkCupom,
    linkCupomAndIndication,
    setLinkCupomAndIndication,
  };
};
