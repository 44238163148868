import { useEffect, useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import styled, { keyframes } from "styled-components";

import CustomStepper from "./stepper";
import NavigationFooter from "./stepFooter";
import { useSaleFormContext } from "core/context/SaleContext";
import NewSalePageHeader from "./newSalePageHeader";
import { useDynamicSteps } from "./stepperContent";
import customTheme from "core/theme/theme";

/*
  Tela principal do pagamento
  aqui é estruturado o layout da página de pagamento
  chamamo so header, o stepper, o conteúdo e o footer
  a lógica não está centrada aqui, mas sim em seus componentes
*/

export interface StepConfig {
  label: string;
}

interface NotificationType {
  id: number;
  message: string;
}

const nomes = [
  "Fernando",
  "Rubens",
  "Esther",
  "Alberto",
  "Simone",
  "Jair",
  "Antonia",
  "Julio",
  "Cristiano",
  "Carolina",
  "Gabriel",
  "Renato",
  "Mauricio",
  "Luan",
  "Joaquim",
  "Adao",
  "Jessica",
  "Fabio",
  "Francisca",
  "Cesar",
  "Rodrigo",
  "Eliane",
  "Vicente",
  "Cleiton",
  "Natasha",
  "Carlos",
  "Severino",
  "Wilson",
  "Willian",
  "Eduardo",
  "Eloisa",
  "Marcos",
  "Luciano",
  "Joao",
  "Priscila",
  "Luiz",
  "Evandro",
  "Adilson",
  "Renan",
  "Alex",
  "Beatriz",
  "Andre",
  "Pedro",
  "Edilson",
  "Vitoria",
  "Tatiane",
  "Marcia",
  "Bruna",
  "Helena",
  "Tiago",
  "Cicero",
  "Nilson",
  "Robson",
  "Julia",
  "Silvio",
  "Joel",
  "Marta",
  "Jonas",
  "Celso",
  "Sergio",
  "Ivan",
  "Hugo",
  "Vinicius",
  "Renata",
  "Claudio",
  "Moises",
  "Caio",
  "Isis",
  "Osmar",
  "Vanessa",
  "Igor",
  "Matheus",
  "Alessandro",
  "Daniel",
  "Cleber",
  "Otavio",
  "Flavia",
  "Aline",
  "Leonardo",
  "Henrique",
  "Gilmar",
  "Pablo",
  "Wagner",
  "Juliano",
  "Tainá",
  "Fabricio",
  "Leandro",
  "Sonia",
  "Gilberto",
  "Monica",
  "Ana",
  "Gabrielly",
  "Larissa",
  "Samuel",
  "Vinicios",
  "Rita",
  "Mauro",
  "Karina",
  "Junior",
  "Diego",
  "Murilo",
  "Miguel",
  "Adriana",
  "Vera",
  "Mateus",
  "Valdir",
  "Nilton",
  "Nicolas",
  "Alice",
  "Diana",
  "Wilian",
  "Luciana",
  "Osvaldo",
  "Artur",
  "Yasmin",
  "Clara",
  "Maicon",
  "Jose",
  "Emerson",
  "Rafael",
  "Sandra",
  "Marcio",
  "Claudia",
  "Luis",
  "Aparecido",
  "Valentina",
  "Marco",
  "Ronaldo",
  "Valter",
  "Talita",
  "Valmir",
  "Mario",
  "Juliana",
  "Camila",
  "Anderson",
  "David",
  "Lorena",
  "Livia",
  "Gustavo",
  "Davi",
  "Edson",
  "Diogo",
  "Douglas",
  "Alan",
  "Reginaldo",
  "Sandro",
  "Luana",
  "Mirella",
  "Gabriela",
  "Rogerio",
  "Luiza",
  "Ailton",
  "Sophia",
  "Antonio",
  "Alisson",
  "Edvaldo",
  "Wesley",
  "Bianca",
  "Reinaldo",
  "Arthur",
  "Italo",
  "Patricia",
  "Roberto",
  "Lucas",
  "Leticia",
  "Jorge",
  "Manoel",
  "Everton",
  "Vitor",
  "Jeferson",
  "Maria",
  "Fernanda",
  "Gilson",
  "Milton",
  "Guilherme",
  "Jean",
  "Felipe",
  "Domingos",
  "Thiago",
  "Geraldo",
  "Paulo",
  "Nelson",
  "Marcelo",
  "Manuel",
  "Manuela",
  "Ricardo",
  "Danilo",
  "Vagner",
  "Laura",
  "Fabiano",
  "Augusto",
  "Amanda",
  "Raquel",
  "Helio",
  "Ruan",
  "Francisco",
  "Ademir",
  "Benedito",
  "Breno",
  "Emanuelle",
  "Victor",
  "Isabela",
  "Ana Clara",
  "Bruno",
  "Mariana",
  "Elias",
  "Adriano",
  "Vanderlei",
  "Flavio",
  "Alexandre",
];
const NewSale = () => {
  const { activeStep, formik } = useSaleFormContext();
  const stepsConfig = useDynamicSteps();
  const CurrentStepComponent = stepsConfig[activeStep]?.component;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [isBubbleVisible, setIsBubbleVisible] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const nome = nomes[Math.floor(Math.random() * nomes.length)];
      const mensagem =
        Math.random() < 0.7
          ? `${nome} acabou de comprar!`
          : `${nome} iniciou a compra!`;

      const id = Date.now(); // ID único para cada notificação
      setNotifications((prev) => [...prev, { id, message: mensagem }]);

      // Remove notificações após 5 segundos
      setTimeout(() => {
        setNotifications((prev) => prev.filter((notif) => notif.id !== id));
      }, 2000);
    }, Math.random() * (8000 - 2000) + 2000);

    return () => clearInterval(interval);
  }, []);

  const openWhatsApp = () => {
    const whatsappLink = "https://wa.me/558331422688";
    window.open(whatsappLink, "_blank");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsBubbleVisible(true);

      // Fechar automaticamente após 20 segundos
      const autoCloseTimer = setTimeout(() => {
        setIsBubbleVisible(false);
      }, 20000);

      return () => clearTimeout(autoCloseTimer);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const closeBubble = () => {
    setIsBubbleVisible(false);
  };

  // Altere a cor da barra do navegador dinamicamente
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", customTheme.COLORS.BLUE3);
    }
  }, [activeStep, theme]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (formik.dirty) {
        event.preventDefault();
        event.returnValue =
          "Tem certeza que deseja sair? Seus dados serão perdidos.";
        return "Tem certeza que deseja sair? Seus dados serão perdidos.";
      }
    };

    // Adiciona o listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove o listener ao desmontar
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formik.dirty]);

  return (
    <Wrapper mobile={isSmallScreen}>
      <NewSalePageHeader />
      <PageContainer mobile={isSmallScreen}>
        {!isSmallScreen && (
          <CustomStepper
            stepsConfig={stepsConfig.map((step) => ({ label: step.label }))}
          />
        )}
        <ContentContainer
          mobile={isSmallScreen}
          activeStepLabel={stepsConfig[activeStep]?.label}
        >
          {CurrentStepComponent && <CurrentStepComponent />}
        </ContentContainer>
        {!(stepsConfig[activeStep]?.label === "Pagamento" && isSmallScreen) && (
          <NavigationFooter stepsLength={stepsConfig.length} />
        )}
      </PageContainer>

      {!isSmallScreen && (
        <WhatsappButton onClick={openWhatsApp}>
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
            alt="WhatsApp"
          />
        </WhatsappButton>
      )}
      {((isSmallScreen && stepsConfig[activeStep]?.label === "CPF/CNPJ") ||
        (isSmallScreen &&
          stepsConfig[activeStep]?.label === "Formas de pagamento")) && (
          <WhatsappButton onClick={openWhatsApp}>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp"
            />
          </WhatsappButton>
        )}

      {/* Bolha de chat */}
      {((isSmallScreen && stepsConfig[activeStep]?.label === "CPF/CNPJ") ||
        (isSmallScreen &&
          stepsConfig[activeStep]?.label === "Formas de pagamento")) && (
          <ChatBubble isVisible={isBubbleVisible}>
            <CloseButton onClick={closeBubble}>&times;</CloseButton>
            <ProfileImage
              src="https://typebotemplates.site/br/whatsapp_dist/typebot_templates2023-10-18-14-14-04/avatar.jpg"
              alt="Profile"
            />
            <ChatText>Olá, posso te ajudar?</ChatText>
          </ChatBubble>
        )}
      {!isSmallScreen && (
        <ChatBubble isVisible={isBubbleVisible}>
          <CloseButton onClick={closeBubble}>&times;</CloseButton>
          <ProfileImage
            src="https://typebotemplates.site/br/whatsapp_dist/typebot_templates2023-10-18-14-14-04/avatar.jpg"
            alt="Profile"
          />
          <ChatText>Olá, posso te ajudar?</ChatText>
        </ChatBubble>
      )}

      {((isSmallScreen && stepsConfig[activeStep]?.label === "CPF/CNPJ") ||
        (isSmallScreen &&
          stepsConfig[activeStep]?.label === "Formas de pagamento")) && (
          <NotificationContainer>
            {notifications.map((notif) => (
              <Notification key={notif.id} isExiting={false}>
                {notif.message}
              </Notification>
            ))}
          </NotificationContainer>
        )}
      {!isSmallScreen && (
        <NotificationContainer>
          {notifications.map((notif) => (
            <Notification key={notif.id} isExiting={false}>
              {notif.message}
            </Notification>
          ))}
        </NotificationContainer>
      )}
    </Wrapper>
  );
};

export default NewSale;

const Wrapper = styled(Box) <{ mobile?: boolean }>`
  display: flex;
  flex: 1;
  
  ${({ mobile }) =>
    mobile
      ? `
        padding-top: 0;
        flex-direction: column;
      `
      : `
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding-top: 1rem;
      `}
`;

const ContentContainer = styled(Box) <{
  activeStepLabel: string;
  mobile?: boolean;
}>`
  display: flex;
  padding: 2rem;
  overflow-y: auto;
  background: ${({ activeStepLabel }) =>
    activeStepLabel === "Pagamento" ? customTheme.COLORS.BLUE3 : "#fff"};
  ${({ mobile }) =>
    mobile
      ? `
        justify-content: start;
        max-width: 100svw;
      `
      : `
        max-height: 350px;
  `}
`;

const PageContainer = styled(Box) <{ mobile?: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${customTheme.COLORS.WHITE};
  overflow: hidden;

  ${({ mobile }) =>
    mobile
      ? `
        flex: 1;
        border: none;
        margin: 0;
        border-radius: 0;
      `
      : `
        width: 1180px;
        border-radius: 1rem;
        border: 4px solid ${customTheme.COLORS.BLACK};
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      `}
`;

// Animação para fade-in e fade-out
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Estilos para a bolha de chat
const ChatBubble = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 90px;
  right: 20px; // Posição padrão
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 999;
  max-width: 250px;
  font-family: Arial, sans-serif;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: scale(${({ isVisible }) => (isVisible ? 1 : 0.9)});
  pointer-events: ${({ isVisible }) => (isVisible ? "all" : "none")};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s
    ease-in-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #888;

  &:hover {
    color: #333;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
`;

const ChatText = styled.div`
  font-size: 14px;
  color: #333;
  line-height: 1.5;
`;

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px; // Posição padrão
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const Notification = styled.div<{ isExiting: boolean }>`
  background-color: #25d366;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: Arial, sans-serif;
  animation: ${({ isExiting }) => (isExiting ? fadeOut : fadeIn)} 0.3s
    ease-in-out;
  transition: opacity 0.3s ease-in-out;
`;

const WhatsappButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px; // Posição padrão
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000;

  img {
    width: 30px;
    height: 30px;
  }

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;
