import { useNavigate } from "react-router-dom";
import { Tabs as BaseTabs } from "@mui/base/Tabs/Tabs";
import { Box } from "@mui/material";

import { NavTabsList, Tab, TabPanel, TabsList } from "./styles";
import FinancialPix from "./financialPix/financialPix";
import { useVerifyAccess } from "core/hooks/authentication/useVerifyAcess";
import { useDateFilter } from "../../../core/context/financialContext";
import FinancialBoleto from "./financialBoleto/financialBoleto";

/*
  Tela do financeiro
  - Acesso: Administradores
  Chama duas tabelas PIX/BOLETO
*/

const NewFinancial = () => {
  const navigate = useNavigate();

  const { activeTab, setActiveTab } = useDateFilter();

  const hasAccess = useVerifyAccess({
    allowedRoles: ["ROLE_ADMIN"],
    restrictedGroup: "CLIENT",
  });

  if (!hasAccess) {
    navigate(-1);
    return null;
  }

  return (
    <BaseTabs
      defaultValue={1}
      value={activeTab}
      onChange={(_, newValue) => setActiveTab(newValue as number)}
    >
      <TabsList>
        <NavTabsList>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tab value={1}>Pix</Tab>
            <Tab value={2}>Boleto</Tab>
          </Box>
        </NavTabsList>
      </TabsList>
      <Box sx={{ minHeight: "70svh" }}>
        <TabPanel value={1}>
          <FinancialPix />
        </TabPanel>
        <TabPanel value={2}>
          <FinancialBoleto />
        </TabPanel>
      </Box>
    </BaseTabs>
  );
};

export default NewFinancial;
