import { useQuery } from "@tanstack/react-query";
import { TSaleFilterRequest } from "core/models/sale";
import { Order } from "core/models/table";
import { fetchSales } from "core/querryes/sale/getSaleQuerry";
import { removeNonNumeric } from "core/utils/globalFunctions";
import { useState } from "react";

const useGetSales = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [saleFilter, setSaleFilter] = useState<TSaleFilterRequest>({
    status: undefined,
    cameThrough: undefined,
    typePayment: undefined,
    sellerCpfOrCnpj: undefined,
    clientCpforCnpj: "",
    indicationCpforCnpj: undefined,
    createDate: undefined,
    sellerId: undefined,
    typeSales: undefined,
    dateEnd: undefined,
    dateStart: undefined,
  });

  const { isSuccess, isLoading, data, refetch } = useQuery({
    queryKey: ["sales", page, rowsPerPage, orderBy, order, saleFilter],
    staleTime: Infinity,
    queryFn: () =>
      fetchSales(
        page,
        rowsPerPage,
        orderBy,
        order,
        saleFilter.status,
        saleFilter.cameThrough,
        saleFilter.typePayment,
        removeNonNumeric(saleFilter.sellerCpfOrCnpj),
        removeNonNumeric(saleFilter.clientCpforCnpj),
        removeNonNumeric(saleFilter.indicationCpforCnpj),
        saleFilter.createDate,
        saleFilter.sellerId,
        saleFilter.typeSales,
        saleFilter.dateEnd,
        saleFilter.dateStart
      ),
  });

  const removeFilter = (attribute: string) => {
    setSaleFilter((prevState) => ({
      ...prevState,
      [attribute]: undefined,
    }));
  };

  return {
    removeFilter,
    isSuccess,
    isLoading,
    data,
    refetch,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    count,
    setCount,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    saleFilter,
    setSaleFilter,
  };
};

export default useGetSales;
