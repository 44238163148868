import ClientAddressInfos from "./content/clientAddressInfos";
import ClientPassword from "./content/clientPassword";
import ClientPersonalInfos from "./content/clientPersonalInfos";
import FindCpfBeforeSale from "./content/findCpfBeforeSale";
import SaleContract from "./content/saleContract";
import SaleCupom from "./content/saleCupom";
import SalePayment from "./content/salePayment";
import WaitingPayment from "./content/waitingPayment";
import { useSaleUrl } from "./hooks/useSaleUrl";

/*
    Aqui é definido quais serão os paços do stepper
    Também é definido qual componente será renderizado em qual passo
    E qual função será chamada antes de avançar para o próximo passo
*/

// Definição do passo e seus componentes
export interface StepConfig {
  label: string;
  component: React.FC;
}

// Componente para cada passo
const StepCPF: React.FC = () => <FindCpfBeforeSale />;
const StepPersonalInfo: React.FC = () => <ClientPersonalInfos />;
const StepPassword: React.FC = () => <ClientPassword />;
const StepAddress: React.FC = () => <ClientAddressInfos />;
const StepCoupon: React.FC = () => <SaleCupom />;
const StepContract: React.FC = () => <SaleContract />;
const StepPaymentMethods: React.FC = () => <SalePayment />;
const StepPayment: React.FC = () => <WaitingPayment />;

// Configuração dos passos com os componentes correspondentes e suas funções
export const stepsConfig: StepConfig[] = [
  { label: "CPF/CNPJ", component: StepCPF },
  { label: "Informações pessoais", component: StepPersonalInfo },
  { label: "Senha", component: StepPassword },
  { label: "Endereço", component: StepAddress },
  { label: "Cupom", component: StepCoupon },
  { label: "Contrato", component: StepContract },
  { label: "Formas de pagamento", component: StepPaymentMethods },
  { label: "Pagamento", component: StepPayment },
];

// Função dinâmica para obter os passos
export const useDynamicSteps = (): StepConfig[] => {
  const { saleTypeByUrl, cupomId } = useSaleUrl();

  // Filtra o passo "Cupom" caso seja uma consultoria
  return stepsConfig.filter(
    (step) =>
      // Remover o passo "Cupom" para consultoria
      !(saleTypeByUrl === "CONSULTORIA" && step.label === "Cupom") &&
      // Remover o passo "Cupom" se cupomId não for null
      !(cupomId !== null && cupomId !== 0 && step.label === "Cupom")
  );
};
