import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import React from "react";

import { useSaleFormContext } from "core/context/SaleContext";
import theme from "core/theme/theme";

const SaleCupom = () => {
  const { cupom, setCupom } = useSaleFormContext();

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  return (
    <StyledContainer mobile={isSmallScreen}>
      <ColumnTitle>Adicionar um Cupom</ColumnTitle>
      <TextField
        autoComplete="off"
        margin="none"
        size={isSmallScreen ? "medium" : "small"}
        fullWidth={isSmallScreen}
        variant={isSmallScreen ? "filled" : "outlined"}
        label="Inserir cupom?"
        value={cupom}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setCupom(event.target.value);
        }}
      />
    </StyledContainer>
  );
};

export default SaleCupom;

const StyledContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  ${({ mobile }) =>
    mobile
      ? `
        justify-content: start;
      `
      : `
        justify-content: center;
  `}
`;

const ColumnContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ColumnTitle = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 18 !important;
  font-weight: bold !important;
`;
