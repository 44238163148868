import React from "react";
import styled from "styled-components";
import { Button, CircularProgress, Typography } from "@mui/material";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import theme from "core/theme/theme";

interface CustomButtonProps extends MuiButtonProps {
  isLoading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children: React.ReactNode;
  activityColor?: string;
}

const StyledButton = styled(Button)<{ $isLoading: boolean }>`
  && {
    background-color: ${(props) =>
      props.$isLoading ? "#cccccc" : theme.COLORS.MAIN_BLUE} !important;
    color: ${(props) =>
      props.$isLoading ? "#999999" : theme.COLORS.WHITE} !important;
    border: 2px solid #333333 !important;
    border-radius: 12px !important;
    box-shadow: 4px 4px 0px #333333 !important;
    text-transform: none !important;
    transition: all 0.2s ease-in-out !important;
    padding: 10px 16px !important;
  }
`;

export const ButtonNeo: React.FC<CustomButtonProps> = ({
  isLoading = false,
  startIcon,
  endIcon,
  children,
  activityColor,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      disabled={isLoading || props.disabled}
      $isLoading={isLoading}
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      type="submit"
    >
      {isLoading ? (
        <CircularProgress
          size={24}
          sx={{ color: activityColor ? activityColor : theme.COLORS.MAIN_BLUE }}
        />
      ) : (
        <Typography>{children}</Typography>
      )}
    </StyledButton>
  );
};

export default ButtonNeo;
