import { Notification } from "app/components/toastNotification/toastNotification";
import { getQrCodeService } from "core/api/user/userService";
import { useDispatch } from "react-redux";
import { setQrCodeData } from "./authSlice";

export const useFetchQrCode = () => {
  const dispatch = useDispatch();

  const fetchQrCode = async (login: string) => {
    try {
      const qrCodeResponse = await getQrCodeService(login);
      const qrCodeData = {
        brcode: qrCodeResponse.data.data[0].brcode,
        id: qrCodeResponse.data.data[0].id,
        value: qrCodeResponse.data.data[0].value,
        typepayment: qrCodeResponse.data.data[0].typepayment,
      };
      dispatch(setQrCodeData(qrCodeData));
    } catch (error) {
      console.error("Erro ao obter QR Code:", error);
      Notification("Erro ao obter QR Code. Tente novamente.", "error");
    }
  };

  return { fetchQrCode };
};
