import { useCallback } from "react";

import { useSaleFormContext } from "core/context/SaleContext";

/* 
    hook para validar a terceira etapa da venda
    - Validação da senha
    - Caso as informações estejam corretas, o usuário é redirecionado para a próxima etapa
*/

const useValidatePassword = () => {
  const { formik, setActiveStep, activeStep } = useSaleFormContext();

  const validatePassword = useCallback(async (): Promise<boolean> => {
    const errors = await formik.validateForm();
    const isValid =
      !errors.password &&
      !errors.confirmPassword;

    if (isValid) {
      formik.setErrors({});
      setActiveStep(activeStep + 1);
      return true;
    }
    return false;
  }, [formik, setActiveStep, activeStep]);

  return { validatePassword };
};

export default useValidatePassword;
