import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import GenericTextField from "app/components/genericTextField/GenericTextField";
import { useSaleFormContext } from "core/context/SaleContext";
import theme from "core/theme/theme";
import styled from "styled-components";
import { useSaleUrl } from "../hooks/useSaleUrl";
/*
  Essa etapa do formulário é responsável por capturar o CPF ou CNPJ do cliente, ao ir pro próximo ele verifica se o CPF ou CNPJ já existe,
  se já existe ele ou vai para cupom ou para contrato, se não ele continua
  seu cadastro normalmente
*/

const FindCpfBeforeSale = () => {
  const { formik } = useSaleFormContext();
  const { saleTypeByUrl } = useSaleUrl();

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  return (
    <StyledContainer mobile={isSmallScreen}>
      <StyledTitle variant="h6" gutterBottom>
        Informe seu CPF {saleTypeByUrl !== "CONSULTORIA" && "ou CNPJ"}
      </StyledTitle>
      <GenericTextField<string>
        error={!!formik.errors.cpforcnpj}
        helperText={formik.errors.cpforcnpj}
        name={"cpforcnpj"}
        label={"Documento"}
        value={formik.values.cpforcnpj}
        props={{
          onChange: formik.handleChange,
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  ${({ mobile }) =>
    mobile
      ? `
        justify-content: start;
        height: min-content;
      `
      : `
        justify-content: center;
  `}
`;

const StyledTitle = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 18 !important;
  font-weight: bold !important;
`;

export default FindCpfBeforeSale;
