import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";

import DefaultFilter, {
  ISelectItem,
} from "app/components/filter/defaultFilter";
import { PageContentContainer } from "app/components/styles";
import DataTablePagination from "app/components/table/pagination/pagination";
import TableHeader from "app/components/table/tableHeader/TableHeader";
import { ContentBody } from "app/views/sellerList/styles";
import useFinancialFilterHook from "core/hooks/filters/financialHook";
import { ITableHeadCell } from "core/models/table";
import Search from "app/components/search/Search";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import Spinner from "app/components/spinner/spinner";
import { SalesCell, SalesTypography } from "app/views/sales/styles";
import {
  formatarData,
  formatarDataSemHoras,
  formatCurrencyBR,
  formatDateBr,
  formatDocument,
  maxNameSize,
} from "core/utils/globalFunctions";
import StyledStatus from "app/components/styledStatus/styledStatus";
import theme from "core/theme/theme";
import { useDateFilter } from "../../../../core/context/financialContext";

export const head: ITableHeadCell[] = [
  { name: "sellerName", label: "Nome do vendedor", align: "left" },
  { name: "sellerCpforcnpj", label: "Vendedor", align: "left" },
  { name: "clientCpforcnpj", label: "Cliente", align: "left" },
  { name: "value", label: "Valor", align: "left" },
  { name: "txid", label: "txid", align: "left" },
  { name: "duedate", label: " Data vencimento", align: "left" },
  { name: "datecreation", label: "Data criação", align: "left" },
  { name: "status", label: "Status", align: "left" },
];

export const items: ISelectItem[] = [
  { name: "CPF / CNPJ", value: "clientcpforcnpj", type: "texto" },
  { name: "Status", value: "status", type: "status2" },
  { name: "Data de pagamento", value: "daterange", type: "daterange" },
];

const FinancialPix = () => {
  const {
    countPix,
    filterPix,
    pagePix,
    pixSales,
    rowsPerPagePix,
    setFilterPix,
    setPagePix,
    setRowsPerPagePix,
    salesInfos,
    isLoading,
  } = useDateFilter();

  const { isOpen, onClose, onOpen } = useFinancialFilterHook();

  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  // remover filtro
  const removeFilter = (attribute: string) => {
    setFilterPix((prevState) => ({
      ...prevState,
      [attribute]: undefined,
    }));
  };

  return (
    <PageContentContainer>
      <TableHeader
        filter={filterPix}
        filterBtn={true}
        filterBtnAction={() => onOpen()}
        remove={removeFilter}
        extraComponents={
          <Search
            searchPlaceHolder="Cpf/cnpj do cliente..."
            cpfClient={filterPix.clientcpforcnpj}
            querrySearching={pixSales.isLoading}
            onChange={(e: string) =>
              setFilterPix((prevState) => ({
                ...prevState,
                clientcpforcnpj: e,
              }))
            }
          />
        }
      />
      {!isLoading && !filterPix.clientcpforcnpj && !filterPix.status && (
        <InfoSection>
          <DateText mobile={isSmallScreen}>
            {`${formatDateBr(filterPix.dateStart!)} - ${formatDateBr(
              filterPix.dateEnd!
            )}`}
          </DateText>
          <InfosContainer mobile={isSmallScreen}>
            <InfosBox
              mobile={isSmallScreen}
              sx={{
                background: theme.COLORS.GREEN2,
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: theme.COLORS.WHITE }}
              >
                Liquidados
              </Typography>
              <Typography sx={{ color: theme.COLORS.WHITE }}>
                {salesInfos?.pixPagoCount}
              </Typography>
              <Typography sx={{ fontSize: "1pc", color: theme.COLORS.WHITE }}>
                {formatCurrencyBR(salesInfos?.pixPagoValue)}
              </Typography>
            </InfosBox>
            <InfosBox
              mobile={isSmallScreen}
              sx={{
                background: theme.COLORS.GRAY4,
              }}
            >
              <Typography
                sx={{ fontWeight: "bold", color: theme.COLORS.WHITE }}
              >
                Pendentes
              </Typography>
              <Typography sx={{ fontSize: "1pc", color: theme.COLORS.WHITE }}>
                {salesInfos?.pixPendenteCount}
              </Typography>
              <Typography sx={{ fontSize: "1pc", color: theme.COLORS.WHITE }}>
                {formatCurrencyBR(salesInfos?.pixPendenteValue)}
              </Typography>
            </InfosBox>
          </InfosContainer>
        </InfoSection>
      )}
      <ContentBody>
        {isSmallScreen ? (
          pixSales.data?.content.map((row: any) => (
            <StyledCard key={row.id}>
              <AttributeRow>
                <AttributeName variant="body2">Cliente:</AttributeName>
                <AttributeValue variant="body2">
                  {row.clientName}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Vendedor:</AttributeName>
                <AttributeValue variant="body2">
                  {row.sellerName}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Valor:</AttributeName>
                <AttributeValue variant="body2">
                  {formatCurrencyBR(row.value)}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Txid:</AttributeName>
                <AttributeValue variant="body2">{row.txid}</AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Criação:</AttributeName>
                <AttributeValue variant="body2">
                  {row.datecreation
                    ? formatarDataSemHoras(row.datecreation)
                    : "--"}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Vencimento:</AttributeName>
                <AttributeValue variant="body2">
                  {row.duedate ? formatarDataSemHoras(row.duedate) : "--"}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Pagamento:</AttributeName>
                <AttributeValue variant="body2">
                  {row.paymentDate ? formatarData(row.paymentDate) : "--"}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Status:</AttributeName>
                <AttributeValue variant="body2">
                  <StyledStatus status={row.status} />
                </AttributeValue>
              </AttributeRow>
            </StyledCard>
          ))
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <StyledDivDataTable>Cliente</StyledDivDataTable>
                <StyledDivDataTable>Vendedor</StyledDivDataTable>
                <StyledDivDataTable>Valor</StyledDivDataTable>
                <StyledDivDataTable>Txid</StyledDivDataTable>
                <StyledDivDataTable>Datas</StyledDivDataTable>
                <StyledDivDataTable>Status</StyledDivDataTable>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ height: 600, textAlign: "center" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <Spinner
                        state={isLoading}
                        size={10}
                        css={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                pixSales.data?.content.map((row: any, index: any) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                    }}
                  >
                    <SalesCell component="th" scope="row" size="small">
                      <Tooltip title={row.clientName}>
                        <SalesTypography fontWeight={"bold"}>
                          {maxNameSize(row.clientName, 32)}
                        </SalesTypography>
                      </Tooltip>
                      <SalesTypography>
                        {formatDocument(row.clientCpforcnpj)}
                      </SalesTypography>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <Tooltip title={row.sellerName}>
                        <SalesTypography fontWeight={"bold"}>
                          {maxNameSize(row.sellerName, 35)}
                        </SalesTypography>
                      </Tooltip>
                      <SalesTypography>
                        {formatDocument(row.sellerCpforcnpj)}
                      </SalesTypography>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography>
                        {formatCurrencyBR(row.value)}
                      </SalesTypography>
                    </SalesCell>
                    <SalesCell
                      component="th"
                      scope="row"
                      size="small"
                      width={220}
                    >
                      <SalesTypography>{row.txid}</SalesTypography>
                    </SalesCell>
                    <SalesCell
                      component="th"
                      scope="row"
                      size="small"
                      width={220}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: "25px",
                          gap: 1,
                        }}
                      >
                        <SalesTypography sx={{ width: "70px" }}>
                          Criação:
                        </SalesTypography>
                        <SalesTypography fontWeight={"bold"}>
                          {row.datecreation
                            ? formatarDataSemHoras(row.datecreation)
                            : "--"}
                        </SalesTypography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: "25px",
                          gap: 1,
                        }}
                      >
                        <SalesTypography sx={{ width: "70px" }}>
                          Vencimento:
                        </SalesTypography>
                        <SalesTypography fontWeight={"bold"}>
                          {row.duedate
                            ? formatarDataSemHoras(row.duedate)
                            : "--"}
                        </SalesTypography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: "25px",
                          gap: 1,
                        }}
                      >
                        <SalesTypography sx={{ width: "70px" }}>
                          Pagamento:
                        </SalesTypography>
                        <SalesTypography fontWeight={"bold"}>
                          {row.paymentDate
                            ? formatarData(row.paymentDate)
                            : "--"}
                        </SalesTypography>
                      </Box>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography>
                        <StyledStatus status={row.status} />
                      </SalesTypography>
                    </SalesCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
        <DataTablePagination
          setPage={setPagePix}
          page={pagePix}
          setRowsPerPage={setRowsPerPagePix}
          rowsPerPage={rowsPerPagePix}
          count={countPix}
        />
      </ContentBody>
      <DefaultFilter
        isOpen={isOpen}
        items={items}
        onChangeFilter={setFilterPix}
        onClose={onClose}
        onOpen={onOpen}
        title="Filtrar transações"
      />
    </PageContentContainer>
  );
};

export default FinancialPix;

export const StyledCard = styled(Box)`
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY4};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
`;

export const AttributeRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const AttributeName = styled(Typography)`
  font-weight: bold;
`;

export const AttributeValue = styled(Typography)`
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const InfoSection = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const DateText = styled(Typography)<{ mobile?: boolean }>`
  ${({ mobile }) =>
    mobile
      ? `
        color: ${theme.COLORS.WHITE} !important;
        background-color:${theme.COLORS.MAIN_BLUE} !important;
        font-size: 1.1pc !important;
        font-weight: bold !important;
      `
      : `
        color: ${theme.COLORS.BLUE3} !important;
        font-size: 1.1pc !important;
        font-weight: bold !important;
        position: absolute !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
        top: 20px !important;
    `}
`;

export const InfosContainer = styled(Box)<{ mobile?: boolean }>`
  background-color: #fff;
  display: flex;
  border-bottom: 2px solid ${theme.COLORS.WHITE};
  ${({ mobile }) =>
    mobile
      ? `
        flex-direction: column;
      `
      : `
        flex-direction: row;
    `}
`;

export const InfosBox = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 1%;
  align-items: center;

  &:first-child {
    border-top-left-radius: ${(props) => (!props.mobile ? " 8px" : "")};
  }

  &:last-child {
    border-top-right-radius: ${(props) => (!props.mobile ? " 8px" : "")};
  }
`;

export const FixedWidthTypography = styled(Typography)`
  width: 150px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-weight: bold;
`;
