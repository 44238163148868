import { useMemo } from "react";
import { useSaleFormContext } from "core/context/SaleContext";
import { removeNonNumeric } from "core/utils/globalFunctions";
import { TSalePayment } from "core/models/sale";

const usePaymentValue = (paymentInfo: TSalePayment) => {
  const { formik, tempCupom } = useSaleFormContext();
  const cleanDocument = removeNonNumeric(formik.values.cpforcnpj) ?? "";

  const escolherValor = useMemo(() => {
    if (cleanDocument.length === 11 && paymentInfo.isFees) {
      return 2 * Number(tempCupom?.valueInstallmentCpf);
    } else if (cleanDocument.length === 11 && !paymentInfo.isFees) {
      return tempCupom?.valuePixCpf;
    } else if (cleanDocument.length === 14 && paymentInfo.isFees) {
      return 2 * Number(tempCupom?.valueInstallmentCnpj);
    } else if (cleanDocument.length === 14 && !paymentInfo.isFees) {
      return tempCupom?.valuePixCnpj;
    }
    return 0;
  }, [cleanDocument, paymentInfo.isFees, tempCupom]);

  return escolherValor;
};

export default usePaymentValue;
