import { Box, Chip, Typography } from "@mui/material";
import styled from "styled-components";

export const ChipsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  margin-bottom: 5px;
`;

export const StyledChip = styled(Chip)`
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.COLORS.GRAY4} !important;
`;

export const InnerSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Section = styled(Box)`
  border-bottom: 2px solid ${({ theme }) => theme.COLORS.GRAY4};
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const LinkText = styled(Typography)`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.COLORS.BLUE3};
  &:hover {
    color: ${({ theme }) => theme.COLORS.BLUE2};
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.COLORS.BLUE3};
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center !important;
`;

export const InnerBox = styled(Box)`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  gap: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em;
`;

export const BoxLink = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
  padding: 1em;
  border: 2px solid;
  border-color: ${({ theme }) => theme.COLORS.GRAY5};
  border-radius: 0.3em;
  background-color: ${({ theme }) => theme.COLORS.WHITE} !important;
  width: ${({ mobile }) => (mobile ? "90svw" : "1200px")};
  justify-content: space-around;
  align-items: center;
  margin: 0.5rem auto;
`;

export const GenerateLinkContent = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  flex-direction: ${({ mobile }) => (mobile ? "column" : "row")};
  flex: 1;
  align-items: center;
  justify-content: space-around;
`;

export const TitleLink = styled(Typography)`
  color: ${({ theme }) => theme.COLORS.BLUE3} !important;
  font-weight: bold !important;
`;
