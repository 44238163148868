import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledCard = styled(Box)`
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY4};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.COLORS.WHITE};
`;

export const AttributeRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const AttributeName = styled(Typography)`
  font-weight: bold;
`;

export const AttributeValue = styled(Typography)`
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const MainActionContainer = styled(Box)`
display: flex;
flex-direction: row;
align-items: center;
border: 1px solid ${({ theme }) => theme.COLORS.BLUE3};
border-radius: 8px;
Height: 30px;
width: 70px;
`;


export const MainActionTitle = styled(Typography)`
font-size: 9px !important;
font-weight: bold !important;
max-width: 40px !important;
color: ${({ theme }) => theme.COLORS.BLUE3} !important;
`;

export const ExtraComponentsContainer = styled(Box)`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 84svw;
`;
