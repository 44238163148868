import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

import { TItemList } from "core/models/lists";
import theme from "core/theme/theme";
import { SalesCell } from "../sales/styles";
import { formatDateBr, formatDateBrWithHours, formatDocument } from "core/utils/globalFunctions";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import React, { useEffect, useMemo, useState } from "react";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { ListsService } from "../../../core/api/lists";
import TableHeader from "../../components/table/tableHeader/TableHeader";
import Search from "../../components/search/Search";
import SendIcon from '@mui/icons-material/Send';
import { TSaleFilterRequest } from "../../../core/models/sale";
import DefaultModal from "app/components/modals/defaultModal/defaultModal";
import { useQuery } from "@tanstack/react-query";
import { Order } from "core/models/table";
import { debounce } from "lodash";
import AsyncAutoComplete from "app/components/assyncAutoComplete/asyncAutoComplete";
import { GET_LISTS } from "core/utils/constants";
import { AttributeName, AttributeRow, AttributeValue, StyledCard } from "../stylesToMobile";

interface IDisplayListProps {
    list?: TItemList[];
    refresh: any;
    listStatus: string;
    refeshList: any;
}

type TList = {
    id: number;
    name: string;
    openDate: string;
    closingDate: string;
    updatedDate: string;
    status: string;
}

export const DisplayList = ({ list, refresh, listStatus, refeshList }: Readonly<IDisplayListProps>) => {

    const [confirmRemove, setConfirmRemove] = useState(false);
    const [moveItemModal, setMoveItemModal] = useState(false);
    const [idToRemove, setIdToRemove] = useState<number>();
    const [tempList, setTempList] = useState<TList>();
    const [tempItemList, setTempItemList] = useState<TItemList>();
    const [isLoading, setIsLoading] = useState(false);
    const currentTheme = useTheme();
    const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
    const isDisabled = listStatus !== 'ABERTO';

    // ? remover cliente da lista
    const handleRemoveClient = async () => {
        await ListsService.removeItemList(idToRemove!);
        refresh();
        setIdToRemove(undefined);
        setConfirmRemove(false);
    };

    const handleSelectList = async (selected: TList) => {
        setTempList(selected);
    };

    const onClearList = () => {
        setTempList(undefined);
    };

    useEffect(() => {
        console.log(tempList);

    }, [tempList]);

    const rmvItem = (id: number) => {
        setIdToRemove(id);
        setConfirmRemove(true);
    };

    const handleCloseModal = () => {
        setMoveItemModal(false);
        setTempList(undefined);
        setTempItemList(undefined);
    };

    const handleConfirmMoveItem = async () => {
        if (tempItemList && tempList) {
            setIsLoading(true);
            try {
                await ListsService.moveItemList(String(tempList.id), String(tempItemList.id));
                setMoveItemModal(false);
                setTempList(undefined);
                setTempItemList(undefined);
                refresh();
                window.location.reload();
            } catch (error) {
                console.error("Erro ao mover item: ", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Container>
            <DefaultDialog
                title="Remover da lista"
                isOpen={confirmRemove}
                onCloseAction={() => setConfirmRemove(false)}
                confirmAction={handleRemoveClient}
            />
            {isSmallScreen ?
                <ContentMobile>
                    {list?.map((row: TItemList, index) => (
                        <StyledCard key={row.id}>
                            <AttributeRow>
                                <AttributeName variant="body2">Nome</AttributeName>
                                <AttributeValue variant="body2">
                                    {row?.nameClient}
                                </AttributeValue>
                            </AttributeRow>
                            <AttributeRow>
                                <AttributeName variant="body2">Cpf</AttributeName>
                                <AttributeValue variant="body2">
                                    {row?.cpforCnpj}
                                </AttributeValue>
                            </AttributeRow>
                            <AttributeRow>
                                <AttributeName variant="body2">Pagamento</AttributeName>
                                <AttributeValue variant="body2">
                                    {formatDateBr(row?.createdAt)}
                                </AttributeValue>
                            </AttributeRow>
                            <ActionBarContainer isDisabled={isDisabled}>
                                <ActionsText>Ações</ActionsText>
                                <ActionsContainer>
                                    <ActionBox
                                        isDisabled={isDisabled}
                                        onClick={() => {
                                            if (!isDisabled) {
                                                setMoveItemModal(true);
                                                setTempItemList(row);
                                            }
                                        }}
                                    >
                                        <ActionText color={theme.COLORS.BLUE3} isDisabled={isDisabled}>
                                            Mover
                                        </ActionText>
                                        <StyledDivider orientation="vertical" flexItem />
                                        <ActionIcons icon={SendIcon} color={theme.COLORS.BLUE3} isDisabled={isDisabled} />
                                    </ActionBox>

                                    <ActionBox
                                        isDisabled={isDisabled}
                                        onClick={() => {
                                            if (!isDisabled) {
                                                rmvItem(row?.id);
                                            }
                                        }}
                                    >
                                        <ActionText color={theme.COLORS.RED} isDisabled={isDisabled}>
                                            Remover
                                        </ActionText>
                                        <StyledDivider orientation="vertical" flexItem />
                                        <ActionIcons icon={CloseIcon} color={theme.COLORS.RED} isDisabled={isDisabled} />
                                    </ActionBox>
                                </ActionsContainer>
                            </ActionBarContainer>
                        </StyledCard>
                    ))}
                </ContentMobile>
                :

                <TableContainer sx={{ maxHeight: 300 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledDivDataTable>Nome</StyledDivDataTable>
                                <StyledDivDataTable>Cpf</StyledDivDataTable>
                                <StyledDivDataTable>Pagamento</StyledDivDataTable>
                                <StyledDivDataTable>Mover</StyledDivDataTable>
                                <StyledDivDataTable>Remover</StyledDivDataTable>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list?.map((row: TItemList, index) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        backgroundColor: index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                                    }}
                                >
                                    <SalesCell component="th" scope="row" size="small">
                                        <Infos>{row?.nameClient}</Infos>
                                    </SalesCell>
                                    <SalesCell component="th" scope="row" size="small">
                                        <Infos>{formatDocument(row?.cpforCnpj)}</Infos>
                                    </SalesCell>
                                    <SalesCell component="th" scope="row" size="small">
                                        <Infos>{formatDateBr(row?.createdAt)}</Infos>
                                    </SalesCell>
                                    <SalesCell>
                                        <Infos>
                                            <IconButton disabled={listStatus !== "ABERTO"} onClick={() => { setMoveItemModal(true); setTempItemList(row) }}>
                                                <SendIcon style={{ color: listStatus !== "ABERTO" ? theme.COLORS.GRAY4 : theme.COLORS.BLUE3 }} />
                                            </IconButton>
                                        </Infos>
                                    </SalesCell>
                                    <SalesCell align="center" component="th" scope="row" size="small">
                                        <Infos>
                                            <IconButton disabled={listStatus !== "ABERTO"} onClick={() => rmvItem(row?.id!)}>
                                                <CloseIcon style={{ color: listStatus !== "ABERTO" ? theme.COLORS.GRAY4 : theme.COLORS.RED }} />
                                            </IconButton>
                                        </Infos>
                                    </SalesCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
            <DefaultModal
                title="Selecione uma lista"
                isOpen={moveItemModal}
                onOpen={() => setMoveItemModal(true)}
                onClose={handleCloseModal}
                children={
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: "0.8rem", minWidth: 380, alignItems: "center" }}>
                        <AsyncAutoComplete
                            label="Pesquise uma lista pelo mês"
                            searchParamName="name"
                            selectedValue={tempList}
                            handleOnSelect={handleSelectList}
                            sortField="name"
                            urlToSearch={GET_LISTS}
                            onClear={onClearList}
                        />
                        {tempList &&
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold" }}>Lista selecionada:  </Typography>
                                <Typography>{tempList.name} - Fechamento: {formatDateBrWithHours(tempList.closingDate)} </Typography>
                            </Box>
                        }

                        <Box sx={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "center", width: '100%' }}>
                            <Button onClick={() => {
                                setTempList(undefined);
                                setTempItemList(undefined);
                                setMoveItemModal(false);
                            }} disabled={isLoading}>
                                CANCELAR
                            </Button>
                            <Button onClick={() => {
                                handleConfirmMoveItem();
                            }} disabled={isLoading || !tempItemList || !tempList}>
                                CONFIRMAR
                            </Button>
                        </Box>
                    </Box>

                }
            />
        </Container >
    );
};

const Container = styled.div`
    background-color: ${theme.COLORS.GRAY7};
    border-radius: 5px;
    display: flex;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #f3f3f3;
    flex-direction: column;
`;

const Infos = styled(Typography)`
    font-size: 0.9pc !important;
`;

const ContentMobile = styled(Box)`
    display: flex;
    flex-direction: column;
    max-height: 50svh;
    overflow: auto;
`;


const ActionBarContainer = styled.div<{ isDisabled: boolean }>`
    height: 40px;
    background: ${theme.COLORS.BLUE3};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 32px);
    margin: 0 -16px -16px;
    border-radius: 0 0 8px 8px;
    border-left: 1px solid rgb(137, 137, 137);
    border-right: 1px solid rgb(137, 137, 137);
    border-bottom: 1px solid rgb(137, 137, 137);
    padding-left: 16px;
    padding-right: 8px;
`;

const ActionsText = styled(Typography)`
    color: ${theme.COLORS.WHITE} !important;
    font-weight: bold !important;
`;

const ActionsContainer = styled.div`
    display: flex;
    gap: 1rem;
    padding: 0.3rem;
`;

const ActionBox = styled.div<{ isDisabled: boolean }>`
    display: flex;
    flex-direction: row;
    background: ${theme.COLORS.WHITE};
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const ActionText = styled(Typography) <{ isDisabled: boolean; color: string }>`
    font-size: 12px !important;
    font-weight: bold !important;
    padding-left: 5px !important;
    color: ${({ isDisabled, color }) => (isDisabled ? theme.COLORS.GRAY4 : color)} !important;
`;

const StyledDivider = styled(Divider)`
    padding-left: 5px;
    padding-right: 5px;
`;

const StyledIconButton = styled(IconButton) <{ isDisabled: boolean }>`
    margin-left: 5px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 10px;
    :hover {
        color: ${({ isDisabled }) => (!isDisabled ? theme.COLORS.BLUE3 : undefined)};
        background: ${({ isDisabled }) => (!isDisabled ? theme.COLORS.GRAY5 : undefined)};
    }
    :disabled {
        background: ${theme.COLORS.GRAY4};
    }
`;

const ActionIcons = styled(
    ({
        icon: Icon,
        color,
        isDisabled,
        ...props
    }: {
        icon: React.ElementType;
        color: string;
        isDisabled: boolean;
    }) => (
        <StyledIconButton disabled={isDisabled} isDisabled={isDisabled} {...props}>
            <Icon sx={{ color: isDisabled ? theme.COLORS.GRAY4 : color, width: 20, height: 20 }} />
        </StyledIconButton>
    )
)``;
