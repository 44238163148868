import { useEffect, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuery } from "@tanstack/react-query";
import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

import { PageContentContainer } from "app/components/styles";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import { ListsService } from "core/api/lists";
import { Order } from "core/models/table";
import { SalesCell, SalesTypography } from "../sales/styles";
import theme from "core/theme/theme";
import DataTablePagination from "app/components/table/pagination/pagination";
import { formatarData, formatarDataSemHoras, formatCurrencyBR, formatDateBr, formatDateBrWithHours } from "core/utils/globalFunctions";
import ManageListModal from "./ManageListModal";
import { TList } from "../../../core/models/lists";
import Spinner from "app/components/spinner/spinner";
import { AttributeName, AttributeRow, AttributeValue, StyledCard } from "../stylesToMobile";
import StyledStatus from "app/components/styledStatus/styledStatus";
import styled from "styled-components";

const Lists = () => {

    const [tempList, setTempList] = useState<TList>();
    const [modal, setModal] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState("name");
    const currentTheme = useTheme();
    const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, list: TList) => {
        setTempList(list);
        setModal(true);
    };

    // ? requisição de listas
    const lists = useQuery({
        queryKey: [orderBy, page, rowsPerPage, order,],
        queryFn: async () => await ListsService.getLists({
            page: page,
            size: rowsPerPage,
            sort: orderBy + "," + order,
        }),
        staleTime: Infinity,
    });

    useEffect(() => {
        if (lists.isSuccess && lists.data) {
            setCount(lists.data.totalElements);
        }
    }, [lists.isSuccess, lists.data]);

    return (
        <PageContentContainer>

            {isSmallScreen ?

                (
                    <>
                        {/* <HeaderToMobile>
                            <Typography>Listas</Typography>
                        </HeaderToMobile> */}
                        {lists.data?.content.map((row: TList, index: number) => (
                            <StyledCard key={row.id}>
                                <AttributeRow>
                                    <AttributeName variant="body2">Mês:</AttributeName>
                                    <AttributeValue variant="body2">
                                        {row?.name}
                                    </AttributeValue>
                                </AttributeRow>
                                <AttributeRow>
                                    <AttributeName variant="body2">Data de abertura:</AttributeName>
                                    <AttributeValue variant="body2">
                                        {formatDateBrWithHours(row?.openDate)}
                                    </AttributeValue>
                                </AttributeRow>
                                <AttributeRow>
                                    <AttributeName variant="body2">Data de fechamento:</AttributeName>
                                    <AttributeValue variant="body2">
                                        {formatDateBrWithHours(row?.closingDate ?? "")}
                                    </AttributeValue>
                                </AttributeRow>
                                <AttributeRow>
                                    <AttributeName variant="body2">Status:</AttributeName>
                                    <AttributeValue variant="body2">
                                        <StyledStatus status={row?.status} />
                                    </AttributeValue>
                                </AttributeRow>
                                <AttributeRow>
                                    <AttributeName variant="body2">Detalhes:</AttributeName>
                                    <AttributeValue variant="body2">
                                        <IconButton sx={{ height: 20 }} onClick={(e) => {
                                            handleClick(e, row);
                                        }}>
                                            <VisibilityIcon style={{ color: theme.COLORS.BLACK }} />
                                        </IconButton>
                                    </AttributeValue>
                                </AttributeRow>
                            </StyledCard>
                        ))}
                    </>
                )
                :
                (<Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledDivDataTable>Mês</StyledDivDataTable>
                            <StyledDivDataTable align="left">
                                Data de abertura
                            </StyledDivDataTable>
                            <StyledDivDataTable align="left">
                                Data de fechamento
                            </StyledDivDataTable>
                            {/*<StyledDivDataTable align="left">*/}
                            {/*    Total de clientes*/}
                            {/*</StyledDivDataTable>*/}
                            <StyledDivDataTable>Status</StyledDivDataTable>
                            <StyledDivDataTable align="center">Detalhes</StyledDivDataTable>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lists.isLoading ? (
                            <TableRow>
                                <TableCell colSpan={5} sx={{ height: 600, textAlign: 'center' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <Spinner
                                            state={lists.isLoading}
                                            size={10}
                                            css={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                            }}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) :
                            lists.data?.content.map((row: TList, index: number) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                        backgroundColor: index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                                    }}
                                >
                                    <SalesCell component="th" scope="row" size="small">
                                        <SalesTypography>{row?.name}</SalesTypography>
                                    </SalesCell>
                                    <SalesCell component="th" scope="row" size="small">
                                        <SalesTypography>{formatDateBrWithHours(row?.openDate)}</SalesTypography>
                                    </SalesCell>
                                    <SalesCell component="th" scope="row" size="small">
                                        <SalesTypography>{formatDateBrWithHours(row?.closingDate ?? "")}</SalesTypography>
                                    </SalesCell>
                                    <SalesCell component="th" scope="row" size="small">
                                        <SalesTypography>{row?.status}</SalesTypography>
                                    </SalesCell>
                                    <SalesCell align="right" component="th" scope="row" size="small">
                                        <SalesTypography>
                                            <IconButton onClick={(e) => {
                                                handleClick(e, row);
                                            }}>
                                                <VisibilityIcon style={{ color: theme.COLORS.BLACK }} />
                                            </IconButton>
                                        </SalesTypography>
                                    </SalesCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>)
            }
            {tempList && <ManageListModal
                list={tempList}
                isOpen={modal}
                onClose={() => setModal(false)}
                onOpen={() => setModal(true)}
                refresh={lists.refetch}
            />}

            <DataTablePagination
                setPage={setPage}
                page={page}
                setRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                count={count}
            />
        </PageContentContainer>
    );
};

// export const HeaderToMobile = styled(Box)`
//     display: flex;
//     justify-content: center;
//     height: 50px;
//     width: 100%;
//     background-color: ${({ theme }) => theme.COLORS.BLUE3};
//     color: ${({ theme }) => theme.COLORS.WHITE};
//     padding: 1rem;
//     border-radius: 8px 8px 0px 0px;
//     flex: 1;
//     font-size: 1.2rem;
//     font-weight: bold;
// `;

export default Lists;