import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useCallback, useEffect, useState } from "react";
import PixIcon from "@mui/icons-material/Pix";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import BoletoSvg from "images/assets/boletoSvg.svg";
import { PageContentContainer } from "app/components/styles";
import DefaultModal from "app/components/modals/defaultModal/defaultModal";
import Spinner from "app/components/spinner/spinner";
import DataTablePagination from "app/components/table/pagination/pagination";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import useSaleInfoHook from "core/hooks/sales/saleInfoHook";
import { TSaleBody, TSaleFilterRequest } from "core/models/sale";
import {
  downloadCSVFromBase64,
  formatDateBr,
  formatDocument,
  formatDocument2,
  formatPhoneNumber,
  maxNameSize,
  removeNonNumeric,
} from "core/utils/globalFunctions";
import SalesInfo from "../../components/modals/sales/salesInfo/salesInfo";
import { ContentBody } from "../sellerList/styles";
import { SalesCell, SalesTypography } from "./styles";
import DefaultFilter, {
  ISelectItem,
} from "app/components/filter/defaultFilter";
import { SaleService } from "core/api/sale/saleService";
import DefaultDialog from "app/components/defaultDialog/defaultDialog";
import { verifyRole } from "core/utils/roles";
import { useAppSelector } from "core/hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import TableHeader from "app/components/table/tableHeader/TableHeader";
import theme from "core/theme/theme";
import Search from "app/components/search/Search";
import StyledStatus from "app/components/styledStatus/styledStatus";
import useGetSales from "./hooks/useGetSales";
import SaleDetailModal from "./components/saleDetailModal";
import { AttributeName, AttributeRow, AttributeValue, ExtraComponentsContainer, MainActionContainer, MainActionTitle, StyledCard } from "../stylesToMobile";
import styled from "styled-components";

/*
  Página para ver todas as vendas
  Listagem de de vendas e seus filtros
  Ações:
    - Baixar tabela
    - Filtrar vendas
    - Ver detalhes da venda
*/

const items: ISelectItem[] = [
  { name: "Status", value: "status", type: "status" },
  { name: "Veio por", value: "cameThrough", type: "texto" },
  { name: "Pagamento", value: "typePayment", type: "type" },
  { name: "Cpf vendedor", value: "sellerCpfOrCnpj", type: "texto" },
  { name: "Cpf cliente", value: "clientCpforCnpj", type: "texto" },
  { name: "Cpf indicação", value: "indicationCpforCnpj", type: "texto" },
  { name: "Intervalo de Datas", value: "daterange", type: "daterange" },
  {
    name: "Produto",
    value: "typeSales",
    type: "genericStatus",
    genericStatusType: {
      options: [
        { key: "Consultoria", value: "CONSULTORIA" },
        { key: "Limpa Nome", value: "LIMPA_NOME" },
      ],
    },
  },
];

function Sales() {
  const [detailIsOpen, setDetailIsOpen] = useState(false);

  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const isSmallerThan900 = useMediaQuery("(max-width:900px)");
  const [tempSale, setTempSale] = useState<TSaleBody>();
  const [filterModal, setFilterModal] = useState(false);
  const [dowloadDialog, setDowloadDialog] = useState(false);
  const [showOptionalColumns, setShowOptionalColumns] = useState(true);
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  const {
    count,
    data,
    isLoading,
    isSuccess,
    page,
    rowsPerPage,
    saleFilter,
    refetch,
    setCount,
    setPage,
    setRowsPerPage,
    setSaleFilter,
    removeFilter,
  } = useGetSales();

  const dowloadTabletWithFilters = useCallback(() => {
    const payload: TSaleFilterRequest = {
      status: saleFilter.status,
      cameThrough: saleFilter.cameThrough,
      typePayment: saleFilter.typePayment,
      sellerCpfOrCnpj: saleFilter.sellerCpfOrCnpj,
      clientCpforCnpj:
        saleFilter.clientCpforCnpj === ""
          ? undefined
          : removeNonNumeric(saleFilter.clientCpforCnpj),
      indicationCpforCnpj: saleFilter.indicationCpforCnpj,
      createDate: saleFilter.createDate,
      sellerId: saleFilter.sellerId,
      typeSales: saleFilter.typeSales,
      dateEnd: saleFilter.dateEnd,
      dateStart: saleFilter.dateStart,
    };
    SaleService.getSaleCsv(payload).then((response) => {
      downloadCSVFromBase64(response, "tabelaFiltrada");
    });
  }, [saleFilter]);

  useEffect(() => {
    if (isSuccess && data) {
      setCount(data.totalElements);
    }
  }, [isSuccess, data]);

  const handleOpenSaleInfo = (obj: TSaleBody) => {
    setTempSale(obj);
    setDetailIsOpen(true);
  };

  const handleConfirmDowload = () => {
    setDowloadDialog(false);
    dowloadTabletWithFilters();
  };

  if (
    !verifyRole(basicUserInfo?.roles, [
      "ROLE_ADMIN",
      "ROLE_SELLER",
      "ROLE_CRUD_SELLER",
      "ROLE_FINANCEIRO",
    ])
  ) {
    navigate(-1);
  }

  useEffect(() => {
    if (tempSale) {
      setTempSale(data?.content.find((sale) => sale.id === tempSale.id));
    }
  }, [isSuccess, data, tempSale]);

  useEffect(() => {
    if (isSmallerThan900) {
      setShowOptionalColumns(false);
    } else {
      setShowOptionalColumns(true);
    }
  }, [isSmallerThan900]);

  return (
    <PageContentContainer>
      {tempSale && (
        <SaleDetailModal
          reRender={refetch}
          sale={tempSale}
          isOpen={detailIsOpen}
          onClose={() => setDetailIsOpen(false)}
        />
      )}
      <TableHeader
        mainActionLabel={isSmallScreen ? "" : "Baixar tabela"}
        mainActionFunction={isSmallScreen ? undefined : () => setDowloadDialog(true)}
        mainIcon={isSmallScreen ? undefined :
          <FileDownloadIcon
            sx={{
              fontSize: "20px",
              color: theme.COLORS.BLUE3,
            }}
          />}
        filterBtn
        filterBtnAction={() => setFilterModal(true)}
        filter={saleFilter}
        remove={removeFilter}
        extraComponents={
          <ExtraComponentsContainer>
            <Search
              searchPlaceHolder="Cpf/cnpj do cliente..."
              cpfClient={saleFilter.clientCpforCnpj}
              querrySearching={isLoading}
              onChange={(e: string) =>
                setSaleFilter((prevState) => ({
                  ...prevState,
                  clientCpforCnpj: e,
                }))
              }
            />
            {isSmallScreen &&
              <MainActionContainer onClick={() => setDowloadDialog(true)}>
                <FileDownloadIcon
                  sx={{
                    fontSize: "15px",
                    color: theme.COLORS.BLUE3,
                    margin: "7px",
                  }}
                />
                <MainActionTitle>Baixar tabela</MainActionTitle>
              </MainActionContainer>}
          </ExtraComponentsContainer>
        }
      />
      <ContentBody>

        {isSmallScreen ?
          <>
            {data?.content.map((row: any, index: number) => (
              <StyledCard key={row.id}>

                <AttributeRow>
                  <AttributeName variant="body2">Cliente:</AttributeName>
                  <AttributeValue variant="body2">
                    <Tooltip title={row?.client?.name || "Sem nome"}>
                      <SalesTypography fontWeight={"bold"}>
                        {maxNameSize(row?.client?.name, 23) || <i>"Sem nome"</i>}
                      </SalesTypography>
                    </Tooltip>
                    {basicUserInfo?.group !== "INDICATION" && (
                      <>
                        <SalesTypography>
                          {formatDocument(row.client.cpforcnpj) || (
                            <i>"Sem cpf/cnpj"</i>
                          )}
                        </SalesTypography>
                        <SalesTypography>
                          {formatPhoneNumber(row.client.telephone) || (
                            <i>"Sem telefone"</i>
                          )}
                        </SalesTypography>
                      </>
                    )}
                  </AttributeValue>
                </AttributeRow>
                <AttributeRow>
                  <AttributeName variant="body2">Vendedor:</AttributeName>
                  <AttributeValue variant="body2">
                    <Tooltip title={row?.seller?.name || "Sem nome"}>
                      <SalesTypography fontWeight={"bold"}>
                        {maxNameSize(row?.seller?.name, 23) || <i>"Sem nome"</i>}
                      </SalesTypography>
                    </Tooltip>
                    {basicUserInfo?.group !== "INDICATION" && (
                      <>
                        <SalesTypography>
                          {formatDocument2(row?.seller?.cpforcnpj) || (
                            <i>"Sem cpf/cnpj"</i>
                          )}
                        </SalesTypography>
                        <SalesTypography>
                          {row?.seller?.email || <i>"Sem email"</i>}
                        </SalesTypography>
                      </>
                    )}
                  </AttributeValue>
                </AttributeRow>
                {basicUserInfo?.group !== "INDICATION" && (
                  <AttributeRow>
                    <AttributeName variant="body2">Criado em:</AttributeName>
                    <AttributeValue variant="body2">
                      <SalesTypography>
                        {row.createdAt ? formatDateBr(row.createdAt) : ""}
                      </SalesTypography>
                    </AttributeValue>
                  </AttributeRow>
                )}
                {basicUserInfo?.group !== "INDICATION" && (
                  <AttributeRow>
                    <AttributeName variant="body2">Indicação:</AttributeName>
                    <AttributeValue variant="body2">
                      <Tooltip title={row.indication?.coupon || "Nenhuma"}>
                        <SalesTypography>
                          {maxNameSize(row.indication?.coupon, 23) || <i>"Nenhuma"</i>}
                        </SalesTypography>
                      </Tooltip>
                      {row.indication?.login && (
                        <SalesTypography>
                          {formatDocument2(row?.indication?.login) || (
                            <i>"Sem cpf/cnpj"</i>
                          )}
                        </SalesTypography>
                      )}
                    </AttributeValue>
                  </AttributeRow>
                )}
                {row.installments !== 0 && basicUserInfo?.group !== "INDICATION" && (
                  <AttributeRow>
                    <AttributeName variant="body2">Parcelas:</AttributeName>
                    <AttributeValue variant="body2">{row.installments}</AttributeValue>
                  </AttributeRow>
                )}


                {basicUserInfo?.group !== "INDICATION" && (
                  <AttributeRow>
                    <AttributeName variant="body2">Veio por:</AttributeName>
                    <AttributeValue variant="body2">
                      <Tooltip title={row.cameThrough || "Sem dado"}>
                        <SalesTypography>
                          {maxNameSize(row.cameThrough, 21) || <i>"Sem dados"</i>}
                        </SalesTypography>
                      </Tooltip>
                    </AttributeValue>
                  </AttributeRow>
                )}
                {basicUserInfo?.group !== "INDICATION" && (
                  <AttributeRow>
                    <AttributeName variant="body2">Status:</AttributeName>
                    <AttributeValue variant="body2">
                      <StyledStatus status={row.status} />
                    </AttributeValue>
                  </AttributeRow>
                )}
                <AttributeRow>
                  <AttributeName variant="body2">Produto:</AttributeName>
                  <AttributeValue variant="body2">
                    {row.typeSales === "CONSULTORIA" ? (
                      <Typography fontWeight={"bold"}>C</Typography>
                    ) : (
                      <Typography fontWeight={"bold"}>LP</Typography>
                    )}</AttributeValue>
                </AttributeRow>
                <AttributeRow>
                  <AttributeName variant="body2">Pagamento:</AttributeName>
                  <AttributeValue variant="body2">
                    {row.typePayment === "BOLETO" ? (
                      <img alt="boleto" src={BoletoSvg} height={"20px"} />
                    ) : (
                      <PixIcon style={{ fontSize: 24 }} />
                    )}
                  </AttributeValue>
                </AttributeRow>
                {basicUserInfo?.group !== "INDICATION" && (
                  <AttributeRow>
                    <AttributeName variant="body2">Detalhes:</AttributeName>
                    <AttributeValue variant="body2">
                      <IconButton onClick={() => handleOpenSaleInfo(row)} sx={{ padding: "0px" }}>
                        <VisibilityIcon style={{ color: theme.COLORS.BLACK }} />
                      </IconButton>
                    </AttributeValue>
                  </AttributeRow>
                )}
              </StyledCard>
            ))}
          </>

          :

          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledDivDataTable></StyledDivDataTable>
                <StyledDivDataTable align="center">Produto</StyledDivDataTable>
                <StyledDivDataTable>Cliente</StyledDivDataTable>
                <StyledDivDataTable>Vendedor</StyledDivDataTable>

                {showOptionalColumns && (
                  <>
                    {basicUserInfo?.group !== "INDICATION" && (
                      <StyledDivDataTable>Indicação</StyledDivDataTable>
                    )}
                    {basicUserInfo?.group !== "INDICATION" && (
                      <StyledDivDataTable>Parcelas</StyledDivDataTable>
                    )}
                    {basicUserInfo?.group !== "INDICATION" && (
                      <StyledDivDataTable>Criado em</StyledDivDataTable>
                    )}
                    {basicUserInfo?.group !== "INDICATION" && (
                      <StyledDivDataTable>Veio por</StyledDivDataTable>
                    )}
                  </>
                )}
                {basicUserInfo?.group !== "INDICATION" && (
                  <StyledDivDataTable>Status</StyledDivDataTable>
                )}
                {basicUserInfo?.group !== "INDICATION" && (
                  <StyledDivDataTable align="center">Opções</StyledDivDataTable>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={10} sx={{ height: 600, textAlign: 'center' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        position: 'relative',
                      }}
                    >
                      <Spinner
                        state={isLoading}
                        size={10}
                        css={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ) :
                data?.content.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                    }}
                  >
                    <SalesCell
                      align="center"
                      component="th"
                      scope="row"
                      size="small"
                    >
                      {row.typePayment === "BOLETO" ? (
                        <img alt="boleto" src={BoletoSvg} height={"20px"}></img>
                      ) : (
                        <PixIcon style={{ fontSize: 30 }} />
                      )}
                    </SalesCell>
                    <SalesCell
                      align="center"
                      component="th"
                      scope="row"
                      size="small"
                    >
                      {row.typeSales === "CONSULTORIA" ? (
                        <Typography fontWeight={"bold"}>C</Typography>
                      ) : (
                        <Typography fontWeight={"bold"}>LP</Typography>
                      )}
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <Tooltip title={row?.client?.name || "Sem nome"}>
                        <SalesTypography fontWeight={"bold"}>
                          {maxNameSize(row?.client?.name, 23) || (
                            <i>"Sem nome"</i>
                          )}
                        </SalesTypography>
                      </Tooltip>
                      {basicUserInfo?.group !== "INDICATION" && (
                        <>
                          <SalesTypography>
                            {formatDocument(row.client.cpforcnpj) || (
                              <i>"Sem cpf/cnpj"</i>
                            )}
                          </SalesTypography>
                          <SalesTypography>
                            {formatPhoneNumber(row.client.telephone) || (
                              <i>"Sem telefone"</i>
                            )}
                          </SalesTypography>
                        </>
                      )}
                    </SalesCell>

                    <SalesCell component="th" scope="row" size="small">
                      <Tooltip title={row?.seller?.name || "Sem nome"}>
                        <SalesTypography fontWeight={"bold"}>
                          {maxNameSize(row?.seller?.name, 23) || (
                            <i>"Sem nome"</i>
                          )}
                        </SalesTypography>
                      </Tooltip>
                      {basicUserInfo?.group !== "INDICATION" && (
                        <>
                          <SalesTypography>
                            {formatDocument2(row?.seller?.cpforcnpj) || (
                              <i>"Sem cpf/cnpj"</i>
                            )}
                          </SalesTypography>
                          <SalesTypography>
                            {row?.seller?.email || <i>"Sem email"</i>}
                          </SalesTypography>
                        </>
                      )}
                    </SalesCell>

                    {showOptionalColumns && (
                      <>
                        {basicUserInfo?.group !== "INDICATION" && (
                          <SalesCell component="th" scope="row" size="small">
                            <Tooltip title={row.indication?.coupon}>
                              <SalesTypography>
                                {maxNameSize(row.indication?.coupon, 23) || (
                                  <i>"Nenhuma"</i>
                                )}
                              </SalesTypography>
                            </Tooltip>
                            {basicUserInfo?.group !== "INDICATION" &&
                              row.indication?.login && (
                                <SalesTypography>
                                  {formatDocument2(row?.indication?.login) || (
                                    <i>"Sem cpf/cnpj"</i>
                                  )}
                                </SalesTypography>
                              )}
                          </SalesCell>
                        )}

                        {basicUserInfo?.group !== "INDICATION" && (
                          <SalesCell component="th" scope="row" size="small">
                            <SalesTypography>
                              {row.installments || ""}
                            </SalesTypography>
                          </SalesCell>
                        )}

                        {basicUserInfo?.group !== "INDICATION" && (
                          <SalesCell component="th" scope="row" size="small">
                            <SalesTypography>
                              {row.createdAt ? formatDateBr(row.createdAt) : ""}
                            </SalesTypography>
                          </SalesCell>
                        )}

                        {basicUserInfo?.group !== "INDICATION" && (
                          <SalesCell component="th" scope="row" size="small">
                            <Tooltip title={row.cameThrough || "Sem dado"}>
                              <SalesTypography>
                                {maxNameSize(row.cameThrough, 21) || ""}
                              </SalesTypography>
                            </Tooltip>
                          </SalesCell>
                        )}
                      </>
                    )}

                    {basicUserInfo?.group !== "INDICATION" && (
                      <SalesCell component="th" scope="row" size="small">
                        <SalesTypography>
                          {<StyledStatus status={row.status} />}
                        </SalesTypography>
                      </SalesCell>
                    )}

                    {basicUserInfo?.group !== "INDICATION" && (
                      <SalesCell
                        align="center"
                        component="th"
                        scope="row"
                        size="small"
                      >
                        <Tooltip title="Detalhes">
                          <IconButton onClick={() => handleOpenSaleInfo(row)}>
                            <VisibilityIcon
                              style={{ color: theme.COLORS.BLACK }}
                            />
                          </IconButton>
                        </Tooltip>
                      </SalesCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        }
        <DataTablePagination
          setPage={setPage}
          page={page}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          count={count}
        />
      </ContentBody>

      <DefaultFilter
        isOpen={filterModal}
        items={items}
        onChangeFilter={setSaleFilter}
        onClose={() => setFilterModal(false)}
        onOpen={() => setFilterModal(true)}
        title="Filtrar vendas"
        changePage={setPage}
      />

      {/* <DefaultModal
        title=""
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        children={
          <SalesInfo reRender={refetch} sales={tempSale as TSaleBody} />
        }
      /> */}
      <DefaultDialog
        title="Baixar tabela?"
        isOpen={dowloadDialog}
        confirmAction={handleConfirmDowload}
        onCloseAction={() => setDowloadDialog(false)}
      />
    </PageContentContainer>
  );
}

export default Sales;


