import { TPixInvoiceSales } from "core/models/financial/pixInvoiceSales";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { FinancialService } from "core/api/financial/financialService";
import {
  getCurrentMonthDates,
  getDefaultYearDates,
} from "core/utils/globalFunctions";
import { TFinancialPixFilterRequest } from "core/models/financial";
import {
  fetchFinancialBoleto,
  fetchFinancialPix,
} from "core/querryes/financial/financialQuerry";
import { Order } from "core/models/table";

interface DateFilterContextProps {
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;

  filterPix: TFinancialPixFilterRequest;
  setFilterPix: React.Dispatch<
    React.SetStateAction<TFinancialPixFilterRequest>
  >;

  filterInvoice: TFinancialPixFilterRequest;
  setFilterInvoice: React.Dispatch<
    React.SetStateAction<TFinancialPixFilterRequest>
  >;

  salesInfos: any;
  isLoading: boolean;
  pixSales: any;
  invoiceSales: any;

  rowsPerPagePix: number;
  setRowsPerPagePix: React.Dispatch<React.SetStateAction<number>>;
  pagePix: number;
  setPagePix: React.Dispatch<React.SetStateAction<number>>;
  countPix: number;
  setCountPix: React.Dispatch<React.SetStateAction<number>>;
  orderPix: Order;
  orderByPix: string;

  rowsPerPageInvoice: number;
  setRowsPerPageInvoice: React.Dispatch<React.SetStateAction<number>>;
  pageInvoice: number;
  setPageInvoice: React.Dispatch<React.SetStateAction<number>>;
  countInvoice: number;
  setCountInvoice: React.Dispatch<React.SetStateAction<number>>;
  orderInvoice: Order;
  orderByInvoice: string;
}

const DateFilterContext = createContext<DateFilterContextProps | undefined>(
  undefined
);

export const DateFilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { yearStart, yearEnd } = getDefaultYearDates();

  const [activeTab, setActiveTab] = useState(1);

  const [rowsPerPageInvoice, setRowsPerPageInvoice] = useState(10);
  const [pageInvoice, setPageInvoice] = useState(0);
  const [countInvoice, setCountInvoice] = useState(0);
  const [orderInvoice] = useState<Order>("asc");
  const [orderByInvoice] = useState("id");

  const [rowsPerPagePix, setRowsPerPagePix] = useState(10);
  const [pagePix, setPagePix] = useState(0);
  const [countPix, setCountPix] = useState(0);
  const [orderPix] = useState<Order>("asc");
  const [orderByPix] = useState("id");

  const [filterInvoice, setFilterInvoice] =
    useState<TFinancialPixFilterRequest>({
      status: undefined,
      clientcpforcnpj: "",
      dateStart: yearStart,
      dateEnd: yearEnd,
    });

  const [filterPix, setFilterPix] = useState<TFinancialPixFilterRequest>({
    status: undefined,
    clientcpforcnpj: "",
    dateStart: yearStart,
    dateEnd: yearEnd,
  });

  const invoiceSales = useQuery({
    queryKey: [
      "financialBoleto",
      pageInvoice,
      rowsPerPageInvoice,
      orderByInvoice,
      orderInvoice,
      filterInvoice,
    ],
    staleTime: Infinity,
    queryFn: () =>
      fetchFinancialBoleto(
        pageInvoice,
        rowsPerPageInvoice,
        orderByInvoice,
        orderInvoice,
        filterInvoice.status,
        filterInvoice.clientcpforcnpj,
        filterInvoice.dateStart,
        filterInvoice.dateEnd
      ),
    refetchOnWindowFocus: false,
  });

  const pixSales = useQuery({
    queryKey: [
      "financialPix",
      pagePix,
      rowsPerPagePix,
      orderByPix,
      orderPix,
      filterPix,
    ],
    staleTime: Infinity,
    queryFn: () =>
      fetchFinancialPix(
        pagePix,
        rowsPerPagePix,
        orderByPix,
        orderPix,
        filterPix.status,
        filterPix.clientcpforcnpj,
        filterPix.dateStart,
        filterPix.dateEnd
      ),
    refetchOnWindowFocus: false,
  });

  const salesInfos = useQuery({
    queryKey: ["financialData", activeTab === 1 ? filterPix : filterInvoice],
    staleTime: Infinity,
    queryFn: () =>
      FinancialService.getAllSalesInfosByRange({
        startDate:
          activeTab === 1 ? filterPix.dateStart! : filterInvoice.dateStart!,
        endDate: activeTab === 1 ? filterPix.dateEnd! : filterInvoice.dateEnd!,
      }),
  });

  useEffect(() => {
    if (pixSales.isSuccess && pixSales.data) {
      setCountPix(pixSales.data.totalElements);
    }
    if (invoiceSales.isSuccess && invoiceSales.data) {
      setCountInvoice(invoiceSales.data.totalElements);
    }
  }, [pixSales, invoiceSales]);

  return (
    <DateFilterContext.Provider
      value={{
        isLoading: salesInfos.isLoading,
        activeTab,
        setActiveTab,
        invoiceSales,
        filterPix,
        setFilterPix,
        salesInfos: salesInfos.data,
        pixSales,
        rowsPerPagePix,
        setRowsPerPagePix,
        pagePix,
        setPagePix,
        countPix,
        setCountPix,
        orderPix,
        orderByPix,
        filterInvoice,
        setFilterInvoice,
        rowsPerPageInvoice,
        setRowsPerPageInvoice,
        pageInvoice,
        setPageInvoice,
        countInvoice,
        setCountInvoice,
        orderInvoice,
        orderByInvoice,
      }}
    >
      {children}
    </DateFilterContext.Provider>
  );
};

export const useDateFilter = () => {
  const context = useContext(DateFilterContext);
  if (!context) {
    throw new Error(
      "useDateFilter deve ser usado dentro de um DateFilterProvider"
    );
  }
  return context;
};
