import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import QRCode from "react-qr-code";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import copy from "clipboard-copy";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  LoginContent,
  LoginTitle,
  PaymentContainer,
  StyledButton,
} from "../styles";
import theme from "core/theme/theme";
import { formatCurrencyBR } from "core/utils/globalFunctions";
import { RootState } from "core/redux/store";
import { axiosInstance } from "core/api/axios/axiosInstance";
import { Notification } from "app/components/toastNotification/toastNotification";
import { SALE } from "core/utils/constants";
import { useAppDispatch } from "core/hooks/reduxHooks";
import { resetQrCodeData } from "core/redux/slices/authSlice";

const WaitingPayment = () => {
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));
  const qrCodeData = useSelector((state: RootState) => state.auth.qrCodeData);
  const dispatch = useAppDispatch();

  const [isClicked, setIsClicked] = useState(false);
  const [counter, setCounter] = useState(10);

  const expirationTime = useRef(Date.now() + 600000);

  const handleCopyClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    try {
      setIsClicked(true);
      await copy(qrCodeData?.brcode!);
      setTimeout(() => {
        setIsClicked(false);
      }, 500);
    } catch (err) {
      console.error("Erro ao copiar o texto:", err);
    }
  };

  const paymentMessage = useMemo(() => {
    return qrCodeData?.typepayment === "BOLETO"
      ? "Valor primeira parcela:"
      : "Valor à vista:";
  }, [qrCodeData?.typepayment]);

  const checkPaymentStatus = () => {
    axiosInstance.get(SALE + "/pix/" + qrCodeData?.id).then((resp) => {
      if (resp.data.data.isPayment === true) {
        Notification("Pagamento concluído", "success");
        dispatch(resetQrCodeData());
        window.location.pathname = "/login";
      } else {
        Notification("Aguardando pagamento", "warning");
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
      if (counter === 0 && qrCodeData) {
        checkPaymentStatus();
        setCounter(10);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <PaymentContainer mobile={isSmallScreen}>
      <LoginTitle mobile={isSmallScreen}>
        Não perca tempo! Efetue o pagamento e aproveite a promoção enquanto
        está disponível!
      </LoginTitle>
      {qrCodeData!.value && (
        <>
          <Typography
            sx={{
              fontWeight: "bold",
              color: theme.COLORS.MAIN_BLUE,
              fontSize: "1.2pc",
            }}
          >
            {paymentMessage}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              color: theme.COLORS.GREEN2,
              fontSize: "1.2pc",
            }}
          >
            {formatCurrencyBR(Number(qrCodeData!.value))}
          </Typography>
        </>
      )}
      {!isSmallScreen && (
        <Box>
          <QRCode value={qrCodeData!.brcode!} size={160} />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: theme.COLORS.MAIN_BLUE,
            fontSize: "1.2pc",
          }}
        >
          Sua Chave PIX Expira em:
        </Typography>
        <Countdown
          date={expirationTime.current}
          renderer={({ minutes, seconds, completed }) => {
            if (completed) {
              return;
            } else {
              return (
                <div className="timer-container">
                  <div className="timer">
                    <span className="timer-value">
                      {String(minutes).padStart(2, "0")}
                    </span>
                    <span className="timer-label">Minutos</span>
                  </div>
                  <div className="timer">
                    <span className="timer-value">
                      {String(seconds).padStart(2, "0")}
                    </span>
                    <span className="timer-label">Segundos</span>
                  </div>
                </div>
              );
            }
          }}
        />
        <Paper
          sx={{
            height: 100,
            width: 200,
            overflowWrap: "break-word",
            overflowY: "scroll",
            padding: 1,
            border: "2px solid #000",
          }}
        >
          {qrCodeData!.brcode}
        </Paper>
        <StyledButton
          isClicked={isClicked}
          onClick={(event) => handleCopyClick(event)}
        >
          {isClicked ? "Pix copiado" : "Copiar pix"}
        </StyledButton>
      </Box>
    </PaymentContainer>
  );
};

export default WaitingPayment;
