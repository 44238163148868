import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LinkIcon from "@mui/icons-material/Link";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { drawerItems } from "./sidebarItems";
import SideBarItem from "../sideBarItem/sideBarItem";
import { IconList } from "../sideBar/styles";
import { useAppSelector } from "core/hooks/reduxHooks";

interface IMobileDrawerProps {
  children: React.ReactNode;
}

const drawerWidth = 240;

const MobileDrawer = ({ children }: Readonly<IMobileDrawerProps>) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  const getPageTitle = () => {
    const urlPart = location.pathname.split("/").pop() || "Home";
    return urlPart.charAt(0).toUpperCase() + urlPart.slice(1);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          color: theme.palette.text.primary,
          boxShadow: "none",
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Toolbar>
          {isSmallScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
          >
            {getPageTitle()}
          </Typography>
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onOpen={() => toggleDrawer(true)}
        onClose={() => toggleDrawer(false)}
      >
        <Box
          sx={{
            width: drawerWidth,
            padding: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <IconList $isOpen={drawerOpen}>
            {drawerItems.map((item: any, index) => {
              if (item.isDivider) {
                return <Divider key={index} flexItem />;
              }

              if (
                item.groupCondition &&
                basicUserInfo?.group !== item.groupCondition
              ) {
                return null;
              }

              if (item.items) {
                return item.items.map((subItem: any, subIndex: any) => (
                  <SideBarItem
                    key={`${index}-${subIndex}`}
                    isOpen={drawerOpen}
                    icon={subItem.icon}
                    link={subItem.link}
                    label={subItem.label}
                    acepptRoles={subItem.acceptRoles}
                    notAcceptGroup={subItem.notAcceptGroup}
                    onClose={
                      isSmallScreen ? () => toggleDrawer(false) : undefined
                    }
                  />
                ));
              }

              return (
                <SideBarItem
                  key={index}
                  isOpen={drawerOpen}
                  icon={item.icon}
                  link={item.link}
                  label={item.label}
                  acepptRoles={item.acceptRoles}
                  notAcceptGroup={item.notAcceptGroup}
                  onClose={
                    isSmallScreen ? () => toggleDrawer(false) : undefined
                  }
                />
              );
            })}
          </IconList>
        </Box>
      </SwipeableDrawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          pt: 5,
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default MobileDrawer;
