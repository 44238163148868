import { useCallback, useState } from "react";
import { useFormik } from "formik";
import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import GenericTextField from "app/components/genericTextField/GenericTextField";
import { LinkText, LoginTitle } from "../styles";
import { formatDocument } from "core/utils/globalFunctions";
import ButtonNeo from "app/components/Button/buttonNeo";
import theme from "core/theme/theme";
import { Validations } from "core/utils/validations";
import { useAppDispatch } from "core/hooks/reduxHooks";
import { login } from "core/redux/slices/authSlice";
import { TLogin } from "core/models/user";
import { useFetchQrCode } from "core/redux/slices/useUserDisabled";

const Authentication = () => {
  const dispatch = useAppDispatch();

  const { fetchQrCode } = useFetchQrCode();

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  const handleLogin = useCallback(
    async (values: TLogin) => {
      setIsLoading(true);
      await dispatch(login({ data: values, fetchQrCode }))
        .then((resp) => {
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [dispatch]
  );
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: Validations.loginSchema,
    onSubmit: handleLogin,
  });

  return (
    <Box
      sx={{
        width: "80%",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        gap: 1,
      }}
    >
      <LoginTitle>Acessar sua conta</LoginTitle>
      <GenericTextField<string>
        props={{
          fullWidth: true,
          onChange: formik.handleChange,
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <PersonOutlineIcon />
              </InputAdornment>
            ),
          },
        }}
        onBlur={() => {
          formik.setFieldValue("login", formatDocument(formik.values.login));
        }}
        value={formik.values.login}
        label="CPF/CNPJ"
        name="login"
        error={!!formik.errors.login}
        helperText={formik.errors.login}
      />
      <GenericTextField<string>
        props={{
          type: showPassword ? "text" : "password",
          fullWidth: true,
          onChange: formik.handleChange,
          onKeyDown: (event) => {
            if (event.key === "Enter") {
              formik.handleSubmit();
            }
          },
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
        value={formik.values.password}
        label="Senha"
        name="password"
        error={!!formik.errors.password}
        helperText={formik.errors.password}
      />
      <LinkText to="/esqueceuSenha">Esqueci minha senha</LinkText>
      <ButtonNeo
        isLoading={isLoading}
        activityColor={theme.COLORS.WHITE}
        onClick={() => formik.handleSubmit()}
        style={{
          color: theme.COLORS.WHITE,
        }}
      >
        Entrar
      </ButtonNeo>
      <Typography>V 2.5</Typography>
    </Box>
  );
};

export default Authentication;
