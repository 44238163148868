import { useMemo } from "react";

import { formatCurrencyBR } from "core/utils/globalFunctions";
import { TCouponResponse } from "core/models/coupons";

export const usePaymentOptions = ({
  cleanDocument,
  cupom,
  urlType,
  handleChangeFees,
  handleChangePaymentType,
  handleChangeInstallments,
}: {
  cleanDocument: string;
  cupom: TCouponResponse;
  urlType: string[];
  handleChangeFees: (isFees: boolean) => void;
  handleChangePaymentType: (type: string) => void;
  handleChangeInstallments: (installment: number | null) => void;
}) => {
  return useMemo(() => {
    const options = [];

    const isNoInstallmentValue =
      Number(cupom?.valuePixCpf) === 497 ||
      Number(cupom?.valuePixCpf) === 997 ||
      Number(cupom?.valuePixCnpj) === 497 ||
      Number(cupom?.valuePixCnpj) === 997;

    // CPF LIMPA NOME - Sem Cupom
    if (
      cleanDocument.length === 11 &&
      !cupom?.nameCoupon &&
      urlType[3] !== "consultoria"
    ) {
      options.push(
        {
          label: "1x R$ 1.500,00 - Pix",
          value: 0,
          onClick: () => {
            handleChangeFees(false);
            handleChangePaymentType("PIX");
            handleChangeInstallments(null);
          },
        },
        {
          label: "1 + 1 R$ 800,00 - Pix + Boleto",
          value: 2,
          onClick: () => {
            if (!isNoInstallmentValue) {
              handleChangeFees(true);
              handleChangePaymentType("BOLETO");
              handleChangeInstallments(2);
            }
          },
        }
      );
    }

    // CPF LIMPA NOME - Com Cupom
    if (cleanDocument.length === 11 && cupom?.valuePixCpf) {
      options.push({
        label: `1x ${formatCurrencyBR(Number(cupom.valuePixCpf))} - Pix`,
        value: 0,
        onClick: () => {
          handleChangeFees(false);
          handleChangePaymentType("PIX");
          handleChangeInstallments(null);
        },
      });
    }

    if (
      cleanDocument.length === 11 &&
      cupom?.valueInstallmentCpf &&
      !isNoInstallmentValue
    ) {
      options.push({
        label: `2x ${formatCurrencyBR(
          Number(cupom.valueInstallmentCpf)
        )} - Pix + Boleto`,
        value: 2,
        onClick: () => {
          handleChangeFees(true);
          handleChangePaymentType("BOLETO");
          handleChangeInstallments(2);
        },
      });
    }

    // CNPJ LIMPA NOME - Sem Cupom
    if (cleanDocument.length === 14 && !cupom?.nameCoupon) {
      options.push(
        {
          label: "1x R$ 2.000,00 - Pix",
          value: 0,
          onClick: () => {
            handleChangeFees(false);
            handleChangePaymentType("PIX");
            handleChangeInstallments(null);
          },
        },
        {
          label: "2x R$ 1.050,00 - Boleto",
          value: 2,
          onClick: () => {
            if (!isNoInstallmentValue) {
              handleChangeFees(true);
              handleChangePaymentType("BOLETO");
              handleChangeInstallments(2);
            }
          },
        }
      );
    }

    // CNPJ LIMPA NOME - Com Cupom
    if (cleanDocument.length === 14 && cupom?.valuePixCnpj) {
      options.push({
        label: `1x ${formatCurrencyBR(Number(cupom.valuePixCnpj))} - Pix`,
        value: 0,
        onClick: () => {
          handleChangeFees(false);
          handleChangePaymentType("PIX");
          handleChangeInstallments(null);
        },
      });
    }

    if (
      cleanDocument.length === 14 &&
      cupom?.valueInstallmentCnpj &&
      !isNoInstallmentValue
    ) {
      options.push({
        label: `2x ${formatCurrencyBR(
          Number(cupom.valueInstallmentCnpj)
        )} - Pix + Boleto`,
        value: 2,
        onClick: () => {
          handleChangeFees(true);
          handleChangePaymentType("BOLETO");
          handleChangeInstallments(2);
        },
      });
    }

    // CONSULTORIA
    if (urlType[3] === "consultoria") {
      options.push({
        label: "1x R$ 297,00 - Pix",
        value: 0,
        onClick: () => {
          handleChangeFees(false);
          handleChangePaymentType("PIX");
          handleChangeInstallments(null);
        },
      });
    }

    return options;
  }, [
    cleanDocument,
    cupom,
    urlType,
    handleChangeFees,
    handleChangePaymentType,
  ]);
};
