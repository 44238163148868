import { useCallback } from "react";

import { useSaleFormContext } from "core/context/SaleContext";
import { removeNonNumeric } from "core/utils/globalFunctions";
import { Notification } from "app/components/toastNotification/toastNotification";
import { SaleService } from "core/api/sale/saleService";
import { useSaleUrl } from "./useSaleUrl";
import { useDynamicSteps } from "../stepperContent";
import { useNavigate } from "react-router-dom";

/* 
    hook para validar a primeira etapa da venda
    - Validação do documento
    - Além da validação do documento, é feita a validação do tipo de venda (consultoria ou limpa nome)
    - Também é feita uma busca para saber se o usuário já adquiriu o produto ou algum outro produto

    ! Caso o usuário já tenha adquirido o produto, é exibido um erro
    ? Caso o usuário seja novo, é redirecionado para a próxima etapa
    ? Caso o usuário seja existente, é redirecionado para a etapa cupom (caso seja limpa nome)
*/

const useValidateDocument = () => {
  const { saleTypeByUrl } = useSaleUrl();
  const stepsConfig = useDynamicSteps();
  const navigate = useNavigate();

  const {
    formik,
    setIsLoading,
    setDocValidated,
    setUserPermissions,
    setActiveStep,
  } = useSaleFormContext();

  const { cupomId } = useSaleUrl();
  const validateDocument = useCallback(async (): Promise<boolean> => {
    setIsLoading(true);
    const clearedDoc = removeNonNumeric(formik.values.cpforcnpj);

    // Validação de CNPJ para consultoria
    if (
      clearedDoc &&
      clearedDoc.length === 14 &&
      saleTypeByUrl === "CONSULTORIA"
    ) {
      Notification("Consultoria não pode ser fornecida a CNPJ.", "error");
      setIsLoading(false);
      return false;
    }

    return await formik.validateForm().then(async (resp) => {
      if (Object.keys(resp).length === 0) {
        const productType =
          saleTypeByUrl === "CONSULTORIA" ? "CONSULTORIA" : "LIMPA_NOME";

        try {
          const response = await SaleService.getValidationDoc(
            clearedDoc ?? "",
            productType
          );

          setDocValidated(true);
          setUserPermissions(response);

          if (response.isExist) {
            navigate("/esqueceuSenha")
            Notification("CPF/CNPJ já adquiriu este produto", "error");
            setDocValidated(false);
            setIsLoading(false);
            return false;
          }

          // Busca os índices das etapas dinamicamente
          const personalInfoIndex = stepsConfig.findIndex(
            (step) => step.label === "Informações pessoais"
          );
          const couponIndex = stepsConfig.findIndex(
            (step) => step.label === "Cupom"
          );
          const contractIndex = stepsConfig.findIndex(
            (step) => step.label === "Contrato"
          );

          // Lógica para avançar para a próxima etapa
          if (!response.isExistForClient) {
            setActiveStep(personalInfoIndex);
            setIsLoading(false);
            return true;
          }

          // Caso o usuário ja tenha cadastro
          if (response.isExistForClient) {
            setIsLoading(false);
            if (cupomId !== null && cupomId !== 0) {
              setActiveStep(contractIndex);
              return true;
            } else {
              setActiveStep(
                productType === "CONSULTORIA" ? contractIndex : couponIndex
              );
            }
            return true;
          }
        } catch (error) {
          Notification("Erro ao validar documento.", "error");
          setIsLoading(false);
          return false;
        }
      }
      setIsLoading(false);
      return false;
    });
  }, [
    formik,
    setIsLoading,
    setDocValidated,
    setUserPermissions,
    setActiveStep,
    saleTypeByUrl,
    stepsConfig,
  ]);

  return { validateDocument };
};

export default useValidateDocument;
