import {
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import styled from "styled-components";

import useSaleTitle from "../hooks/useGetPaymentTitle";
import { formatCurrencyBR, removeNonNumeric } from "core/utils/globalFunctions";
import { useSaleFormContext } from "core/context/SaleContext";
import usePaymentValue from "../hooks/usePaymentFinalValue";
import { usePaymentOptions } from "../hooks/userPaymentOptions";
import theme from "core/theme/theme";

/* 
  Esse componente é responsável por exibir o valor da venda e as opções de pagamento disponíveis.
  Se tiver desconto irá mostra-lo
*/

const SalePayment = () => {
  const saleTitle = useSaleTitle();
  const { tempCupom, formik, paymentInfo, setPaymentInfo } =
    useSaleFormContext();

  const choosenValue = usePaymentValue(paymentInfo);

  const paymentOptions = usePaymentOptions({
    cleanDocument: removeNonNumeric(formik.values.cpforcnpj)!,
    cupom: tempCupom,
    urlType: window.location.href.split("/"),
    handleChangeFees: (isFees) =>
      setPaymentInfo((prev) => ({ ...prev, isFees })),
    handleChangePaymentType: (type) =>
      setPaymentInfo((prev) => ({ ...prev, typePayment: type as any })),
    handleChangeInstallments: (installment) =>
      setPaymentInfo((prev) => ({
        ...prev,
        installments: installment as any,
      })),
  });

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  return (
    <StyledContainer mobile={isSmallScreen}>
      <StyledTitle>
        Valor:{" "}
        {choosenValue
          ? formatCurrencyBR(Number(choosenValue))
          : `R$ ${saleTitle}`}
      </StyledTitle>
      <SelectContainer>
        <InputLabel>Pagamento</InputLabel>
        <Select
          label="Pagamento"
          onChange={(event) => {
            const selectedOption = paymentOptions.find(
              (option) => option.value === event.target.value
            );
            if (selectedOption) {
              selectedOption.onClick();
            }
          }}
          MenuProps={{
            PaperProps: {
              style: { maxHeight: 150, overflow: "auto" },
            },
          }}
        >
          {paymentOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </SelectContainer>
    </StyledContainer>
  );
};

export default SalePayment;

const StyledTitle = styled(Typography)`
  font-size: 22px !important;
  font-weight: bold !important;
  color: ${theme.COLORS.BLUE3} !important;
`;

const StyledContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  ${({ mobile }) =>
    mobile
      ? `
        justify-content: start;
      `
      : `
        justify-content: center;
  `}
`;

const SelectContainer = styled(FormControl)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
