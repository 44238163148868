import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import VendaLogo from "images/assets/logoVenda.svg";
import useSaleTitle from "./hooks/useSaleTitle";
import theme from "core/theme/theme";

const NewSalePageHeader = () => {
  const saleTitle = useSaleTitle();

  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  return (
    <Box
      textAlign={"center"}
      sx={{
        background: isSmallScreen ? "#fff" : "transparent",
        paddingTop: isSmallScreen ? 1 : 0,
      }}
    >
      <img alt="sadjaslkdj" src={VendaLogo} height={isSmallScreen ? 40 : 80} />
      <Typography
        fontWeight={"bold"}
        color={theme.COLORS.BLUE3}
        fontSize={isSmallScreen ? 15 : 20}
      >
        Adquirir serviço de {saleTitle}
      </Typography>
    </Box>
  );
};

export default NewSalePageHeader;
