import { useState } from "react";

import { TGenerateLinkBodyRequest } from "core/models/sale/link";

export const useLinkRequestManager = () => {
  const [linkRequestLimpaNomeOnlyCupom, setLinkRequestLimpaNomeOnlyCupom] =
    useState<TGenerateLinkBodyRequest>({
      idIndiction: "",
      numero: 1,
      cupon: "",
    });

  const [linkRequestOnlyIndication, setLinkRequestOnlyIndication] =
    useState<TGenerateLinkBodyRequest>({
      idIndiction: "",
      numero: 1,
    });

  const [linkRequest, setLinkRequest] = useState<TGenerateLinkBodyRequest>({
    idIndiction: "",
    numero: 1,
    cupon: "",
  });

  return {
    linkRequestLimpaNomeOnlyCupom,
    setLinkRequestLimpaNomeOnlyCupom,
    linkRequestOnlyIndication,
    setLinkRequestOnlyIndication,
    linkRequest,
    setLinkRequest,
  };
};
