import { PageContentContainer } from "app/components/styles";
import { ButtonDownload, Container, ContractBox } from "./styles";
import { Box, Button, Icon } from "@mui/material";
import { useAppSelector } from "core/hooks/reduxHooks";
import DownloadIcon from "@mui/icons-material/Download";
import { useQuery } from "@tanstack/react-query";
import { fetchClientDeal } from "core/querryes/client/clientDealQuerry";
import { useNavigate } from "react-router-dom";
import { TRole, verifyRole } from "core/utils/roles";
import { ClientClearNameDeal } from "../newSale/content/deal";
import { formatCEP, removeNonNumeric } from "core/utils/globalFunctions";
import { useEffect } from "react";
import { ClientCoursesDeal } from "app/components/coursesDeal/coursesDeal";
import { NewClearNameDeal997 } from "app/components/newDeal/newDeal997";
import { NewClearNameDeal497 } from "app/components/newDeal/newDeal497";

const Contract = () => {
  const navigate = useNavigate();
  const basicUserInfo = useAppSelector((state) => state.auth.userInfo);
  const clientInfos = useAppSelector((state) => state.auth.client);
  const acceptRoles: TRole[] = ["ROLE_COURSES", "ROLE_ADMIN"];
  const notAcceptGroup: string = "ROLE_ADMIN";

  const dealInfo = useQuery({
    queryKey: ["installments"],
    queryFn: () => fetchClientDeal(),
  });

  useEffect(() => {
    console.log(dealInfo.data);
  }, [dealInfo]);

  function salePrice(
    saleType: string,
    documentType: string,
    paymentType: string,
    coupon?: { pixValue?: number; installmentValue?: number }
  ): number {
    let price: number;
    if (saleType === "CONSULTORIA") {
      price = 297;
    } else {
      const prices: any = {
        PIX: {
          CPF: 1500,
          CNPJ: 2000,
        },
        BOLETO: {
          CPF: 800,
          CNPJ: 1050,
        },
      };
      price = prices[paymentType]?.[documentType] || 0;
    }
    if (coupon?.installmentValue && paymentType === "BOLETO") {
      price = coupon?.installmentValue;
    }
    if (coupon?.pixValue && paymentType === "PIX") {
      price = coupon?.pixValue;
    }
    return price;
  }

  // const handleClick = () => {
  //   const content =
  //     handleDeal(
  //       clientInfos?.name || " ",
  //       clientInfos?.cpforcnpj || " ",
  //       clientInfos?.address || " ",
  //       dealInfo.data
  //     ) || "";

  //   const htmlString = ReactDOMServer.renderToString(<div>{content}</div>);

  //   const htmlContent = `
  //           <html>
  //             <head>
  //               <title>Contrato</title>
  //             </head>
  //             <body>
  //               <div style="max-width: 800px; margin: 0 auto;">
  //                 ${htmlString}
  //               </div>
  //             </body>
  //           </html>
  //         `;
  //   const newWindow = window.open();
  //   if (newWindow) {
  //     newWindow.document.write(htmlContent);
  //     newWindow.document.close();
  //   }
  // };

  if (
    !verifyRole(basicUserInfo?.roles, acceptRoles) ||
    basicUserInfo?.group === notAcceptGroup
  ) {
    navigate(-1);
    return null;
  }

 return (
    <PageContentContainer>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            overflowY: "auto",
            flexDirection: "column",
            gap: 5,
            alignItems: "center",
            width: "100%",
            flex: 1,
          }}
        >
          {dealInfo?.data?.map((item: any, index: number) => (
            <>
              <ContractBox key={index}>
                {item.etypeSales === "LIMPA_NOME" ? (
                  (() => {
                    const price = salePrice(
                      item.etypeSales,
                      item.typeClient,
                      "PIX",
                      item.couponDTO
                    );

                    const today = new Date().toISOString().split("T")[0];
                    const saleDate = new Date(item.dateSale).toISOString().split("T")[0];

                    if (price === 997 && saleDate === today) {
                      return (
                        <NewClearNameDeal997
                          createDate={item.dateSale}
                          dowloadFunction={true}
                          clientName={clientInfos?.name!}
                          clientDocument={removeNonNumeric(clientInfos?.cpforcnpj)!}
                          clientAddressAsString={`${clientInfos?.address}, ${clientInfos?.neighborhood
                            }, ${clientInfos?.city}, ${clientInfos?.uf}, ${formatCEP(
                              clientInfos?.cep!
                            )}`}
                        />
                      );
                    } else if (price === 497 && saleDate === today) {
                      return (
                        <NewClearNameDeal497
                          createDate={item.dateSale}
                          dowloadFunction={true}
                          clientName={clientInfos?.name!}
                          clientDocument={removeNonNumeric(clientInfos?.cpforcnpj)!}
                          clientAddressAsString={`${clientInfos?.address}, ${clientInfos?.neighborhood
                            }, ${clientInfos?.city}, ${clientInfos?.uf}, ${formatCEP(
                              clientInfos?.cep!
                            )}`}
                        />
                      );
                    } else {
                      return (
                        <ClientClearNameDeal
                          createDate={item.dateSale}
                          dowloadFunction={true}
                          clientName={clientInfos?.name!}
                          clientDocument={removeNonNumeric(clientInfos?.cpforcnpj)!}
                          fullValue={salePrice(
                            item.etypeSales,
                            item.typeClient,
                            "PIX",
                            item.couponDTO
                          )}
                          halfValue={salePrice(
                            item.etypeSales,
                            item.typeClient,
                            "BOLETO",
                            item.couponDTO
                          )}
                          clientAddressAsString={`${clientInfos?.address}, ${clientInfos?.neighborhood
                            }, ${clientInfos?.city}, ${clientInfos?.uf}, ${formatCEP(
                              clientInfos?.cep!
                            )}`}
                        />
                      );
                    }
                  })()
                ) : (
                  <ClientCoursesDeal
                    createDate={item.dateSale}
                    dowloadFunction={true}
                    clientName={clientInfos?.name!}
                    clientDocument={removeNonNumeric(clientInfos?.cpforcnpj)!}
                    clientAddressAsString={`${clientInfos?.address}, ${clientInfos?.neighborhood
                      }, ${clientInfos?.city}, ${clientInfos?.uf}, ${formatCEP(
                        clientInfos?.cep!
                      )}`}
                  />
                )}

              </ContractBox>
            </>
          ))}
        </Box>
      </Container>
    </PageContentContainer>
  );
};

export default Contract;
