import React, { useState } from "react";
import {
  Button,
  Autocomplete,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

import { TGenerateLinkBodyRequest } from "core/models/sale/link";
import { Container } from "./styles";
import { fetchIndicationsByCoupon } from "core/querryes/indication/indicationQuerry";
import { useQuery } from "@tanstack/react-query";
import { TIndicationUser } from "core/models/indication";
import { fetchCoupons } from "core/querryes/coupon/couponQuerry";
import { Order } from "core/models/table";
import { TCouponResponse } from "core/models/coupons";

interface IGenerateLinkProps {
  payload: TGenerateLinkBodyRequest;
  setPayload: React.Dispatch<React.SetStateAction<TGenerateLinkBodyRequest>>;
  setIndicationName?: React.Dispatch<React.SetStateAction<string>>;
  setCouponName?: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  haveIndication?: boolean;
  onlyCupom?: boolean;
  saleType: string;
}

function GenerateLink(props: Readonly<IGenerateLinkProps>) {
  const {
    payload,
    setPayload,
    onSubmit,
    onlyCupom,
    saleType,
    setIndicationName,
    setCouponName,
  } = props;

  const [searchIndication, setSearchIndication] = useState("");
  const [rowsPerPage] = useState(100);
  const [page] = useState(0);
  const [order] = useState<Order>("asc");
  const [orderBy] = useState("name");
  const [isWaiting, setIsWaiting] = useState(false);

  // pegar as indicações para listar
  const indicationsResponse = useQuery({
    queryKey: ["indications", searchIndication],
    enabled: !onlyCupom,
    queryFn: () => fetchIndicationsByCoupon(searchIndication),
  });

  // pegar as indicações para listar
  const cupomResponse = useQuery({
    queryKey: ["cupom"],
    enabled: saleType === "limpaNome",
    queryFn: () => fetchCoupons(page, rowsPerPage, orderBy, order),
  });

  const handleClick = () => {
    setIsWaiting(true);
    onSubmit();
    setTimeout(() => {
      setIsWaiting(false);
    }, 1000);
  };

  return (
    <Container>
      {/* select de cupom */}
      {cupomResponse.data?.content && saleType === "limpaNome" && (
        <FormControl fullWidth>
          <InputLabel id="cupom">Cupom</InputLabel>
          <Select
            label="Cupom"
            fullWidth
            onChange={(event: any) => {
              const selectedCoupon = event.target.value;
              console.log(selectedCoupon);
              setPayload((prevState) => ({
                ...prevState,
                cupon: selectedCoupon ?? "",
              }));
              console.log("Cupom atualizado:", selectedCoupon);
              setCouponName &&
                setCouponName(
                  cupomResponse.data?.content.find(
                    (cupom: TCouponResponse) => cupom.id === selectedCoupon
                  )?.nameCoupon ?? ""
                );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  maxWidth: 300,
                },
              },
            }}
          >
            {cupomResponse.data.content.map((cupom: TCouponResponse) => (
              <MenuItem key={cupom.id} value={cupom.id}>
                {cupom.nameCoupon}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* search de indicações */}
      {!onlyCupom && (
        <Autocomplete
          sx={{ width: "100%" }}
          inputValue={searchIndication}
          onInputChange={(event, newInputValue) => {
            setSearchIndication(newInputValue);
          }}
          onChange={(event: any, newValue: TIndicationUser | null) => {
            setPayload((prevState) => ({
              ...prevState,
              idIndiction: newValue?.id ?? "",
            }));
            setIndicationName
              ? setIndicationName(newValue?.coupon ?? "")
              : console.log("");
          }}
          options={indicationsResponse.data?.content || []}
          getOptionLabel={(option) => option.coupon}
          renderInput={(params) => (
            <TextField variant="outlined" {...params} label="Indicação" />
          )}
        />
      )}

      <Button
        disabled={isWaiting || (!payload.idIndiction && !payload.cupon)}
        onClick={handleClick}
      >
        Gerar link
      </Button>
    </Container>
  );
}

export default GenerateLink;
