/* 
    Esse hook retira algumas informações da URL para serem utilizadas em outras partes do código.

    É pego o token da url no index 4 que está em b64 e traduzido

    esse token contem 5 index divididos por &

    1 -> Ver com saniel
    2 -> Id indicação
    3 -> Campanha que a venda pertence
    4 -> Ver com saniel
    5 -> Id do cupon da compra
*/

export const useSaleUrl = () => {
  let urlPath = window.location.href.split("/");

  let saleTypeByUrl =
    urlPath[3] === "consultoria" ? "CONSULTORIA" : "LIMPA_NOME";

  let token = urlPath[4];

  let decodedUrlToken = atob(urlPath[4]);

  let cupomParameter = decodedUrlToken.split("&")[4] ?? null;

  let cupomId = isNaN(Number(cupomParameter)) ? null : Number(cupomParameter);

  return {
    saleTypeByUrl,
    cupomId,
    token,
  };
};
