import { useSaleFormContext } from "core/context/SaleContext";
import { useSaleUrl } from "./useSaleUrl";
import { useEffect } from "react";
import { CouponService } from "core/api/coupons/couponService";
/**
 * Hook para buscar o cupom com base no cupomId da URL
 * e setá-lo no contexto de venda.
 */

const useGetCupomByLink = () => {
  const { cupomId } = useSaleUrl();
  const { setTempCupom, setIsLoading } = useSaleFormContext();

  useEffect(() => {
    const fetchCoupon = async () => {
      if (cupomId !== null && cupomId !== 0) {
        setIsLoading(true);
        try {
          const coupon = await CouponService.getCouponById(cupomId);
          setTempCupom(coupon);
          //Notification("Cupom carregado com sucesso!", "success");
        } catch (err: any) {
          console.warn("Erro ao buscar o cupom:", err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchCoupon();
  }, [cupomId, setTempCupom, setIsLoading]);
};

export default useGetCupomByLink;
