import { useCallback } from "react";
import axios from "axios";

import { useSaleFormContext } from "core/context/SaleContext";

const useCepData = () => {
  const { formik, setIsLoading } = useSaleFormContext();

  const getCepData = useCallback(
    async (event: any) => {
      const cep = event.target.value.replace(/\D/g, "");

      if (cep.length !== 8) {
        console.error("CEP inválido");
        return;
      }

      setIsLoading(true);

      try {
        const response = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (response.data.erro) {
          console.error("CEP não encontrado");
        } else {
          formik.setFieldValue("city", response.data.localidade || "");
          formik.setFieldValue("address", response.data.logradouro || "");
          formik.setFieldValue("neighborhood", response.data.bairro || "");
          formik.setFieldValue("uf", response.data.uf || "");
        }
      } catch (error) {
        console.error("Erro ao buscar CEP:", error);
      } finally {
        formik.setErrors({});
        setIsLoading(false);
      }
    },
    [formik, setIsLoading]
  );

  return { getCepData };
};

export default useCepData;
