import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DefaultFilter, {
  ISelectItem,
} from "app/components/filter/defaultFilter";
import { PageContentContainer } from "app/components/styles";
import DataTablePagination from "app/components/table/pagination/pagination";
import TableHeader from "app/components/table/tableHeader/TableHeader";
import { ContentBody } from "app/views/sellerList/styles";
import useFinancialFilterHook from "core/hooks/filters/financialHook";
import { ITableHeadCell } from "core/models/table";
import Search from "app/components/search/Search";
import { StyledDivDataTable } from "app/components/table/tableHead/styles";
import { SalesCell, SalesTypography } from "app/views/sales/styles";
import {
  formatarData,
  formatarDataSemHoras,
  formatCurrencyBR,
  formatDateBr,
  formatDocument,
} from "core/utils/globalFunctions";
import Spinner from "app/components/spinner/spinner";
import StyledStatus from "app/components/styledStatus/styledStatus";
import theme from "core/theme/theme";
import { useDateFilter } from "../../../../core/context/financialContext";
import styled from "styled-components";
import {
  AttributeName,
  AttributeRow,
  AttributeValue,
  DateText,
  FixedWidthTypography,
  InfosBox,
  InfosContainer,
  InfoSection,
  StyledCard,
} from "../financialPix/financialPix";

export const head: ITableHeadCell[] = [
  { name: "sellerName", label: "Nome do vendedor", align: "left" },
  { name: "clientName", label: "Nome do cliente", align: "left" },
  { name: "sellerCpforcnpj", label: "Vendedor", align: "left" },
  { name: "clientCpforcnpj", label: "Cliente", align: "left" },
  { name: "value", label: "Valor", align: "left" },
  { name: "nossonumero", label: "Nosso Número", align: "left" },
  { name: "duedate", label: "Vencimento", align: "center" },
  { name: "issuancedate", label: "Emissão", align: "center" },
  { name: "status", label: "Status", align: "center" },
];

export const items: ISelectItem[] = [
  { name: "CPF / CNPJ", value: "clientcpforcnpj", type: "texto" },
  { name: "Status", value: "status", type: "status2" },
  { name: "Data de pagamento", value: "daterange", type: "daterange" },
];

const FinancialBoleto = () => {
  const {
    pageInvoice,
    countInvoice,
    filterInvoice,
    setPageInvoice,
    setFilterInvoice,
    rowsPerPageInvoice,
    setRowsPerPageInvoice,
    invoiceSales,
    salesInfos,
    isLoading,
  } = useDateFilter();

  const { isOpen, onClose, onOpen } = useFinancialFilterHook();

  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  const removeFilter = (attribute: string) => {
    setFilterInvoice((prevState) => ({
      ...prevState,
      [attribute]: undefined,
    }));
  };

  return (
    <PageContentContainer>
      <TableHeader
        filter={filterInvoice}
        filterBtn={true}
        filterBtnAction={() => onOpen()}
        remove={removeFilter}
        extraComponents={
          <Search
            searchPlaceHolder="Cpf/cnpj do cliente..."
            cpfClient={filterInvoice.clientcpforcnpj}
            querrySearching={invoiceSales.isLoading}
            onChange={(e: string) =>
              setFilterInvoice((prevState) => ({
                ...prevState,
                clientcpforcnpj: e,
              }))
            }
          />
        }
      />
      {!isLoading &&
        !filterInvoice.clientcpforcnpj &&
        !filterInvoice.status && (
          <InfoSection>
            <DateText mobile={isSmallScreen}>
              {`${formatDateBr(filterInvoice.dateStart!)} - ${formatDateBr(
                filterInvoice.dateEnd!
              )}`}
            </DateText>
            <InfosContainer mobile={isSmallScreen}>
              <InfosBox
                mobile={isSmallScreen}
                sx={{
                  background: theme.COLORS.GREEN2,
                }}
              >
                <FixedWidthTypography sx={{ width: "120px" }}>
                  Liquidados
                </FixedWidthTypography>
                <FixedWidthTypography sx={{ width: "60px" }}>
                  {salesInfos?.boletoPagoCount}
                </FixedWidthTypography>
                <FixedWidthTypography>
                  {formatCurrencyBR(salesInfos?.boletoPagoValue)}
                </FixedWidthTypography>
              </InfosBox>
              <InfosBox
                mobile={isSmallScreen}
                sx={{
                  background: theme.COLORS.GRAY4,
                }}
              >
                <FixedWidthTypography sx={{ width: "120px" }}>
                  Pendentes
                </FixedWidthTypography>
                <FixedWidthTypography sx={{ width: "60px" }}>
                  {salesInfos?.boletoPendenteCount}
                </FixedWidthTypography>
                <FixedWidthTypography>
                  {formatCurrencyBR(salesInfos?.boletoPendenteValue)}
                </FixedWidthTypography>
              </InfosBox>
              <InfosBox
                mobile={isSmallScreen}
                sx={{
                  background: theme.COLORS.YELLOW,
                }}
              >
                <FixedWidthTypography sx={{ width: "120px" }}>
                  Em pagamento
                </FixedWidthTypography>
                <FixedWidthTypography sx={{ width: "60px" }}>
                  {salesInfos?.boletoEmPagamentoCount}
                </FixedWidthTypography>
                <FixedWidthTypography>
                  {formatCurrencyBR(salesInfos?.boletoEmPagamentoValue)}
                </FixedWidthTypography>
              </InfosBox>
            </InfosContainer>
          </InfoSection>
        )}
      <ContentBody>
        {isSmallScreen ? (
          invoiceSales.data?.content.map((row: any) => (
            <StyledCard key={row.id}>
              <AttributeRow>
                <AttributeName variant="body2">Cliente:</AttributeName>
                <AttributeValue variant="body2">
                  {row.clientName}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Vendedor:</AttributeName>
                <AttributeValue variant="body2">
                  {row.sellerName}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Valor:</AttributeName>
                <AttributeValue variant="body2">
                  {formatCurrencyBR(row.value)}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Nosso Número:</AttributeName>
                <AttributeValue variant="body2">
                  {row.nossonumero}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Criação:</AttributeName>
                <AttributeValue variant="body2">
                  {row.datecreation
                    ? formatarDataSemHoras(row.datecreation)
                    : "--"}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Vencimento:</AttributeName>
                <AttributeValue variant="body2">
                  {row.duedate ? formatarDataSemHoras(row.duedate) : "--"}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Pagamento:</AttributeName>
                <AttributeValue variant="body2">
                  {row.paymentDate ? formatarData(row.paymentDate) : "--"}
                </AttributeValue>
              </AttributeRow>
              <AttributeRow>
                <AttributeName variant="body2">Status:</AttributeName>
                <AttributeValue variant="body2">
                  <StyledStatus status={row.status} />
                </AttributeValue>
              </AttributeRow>
            </StyledCard>
          ))
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <StyledDivDataTable>Cliente</StyledDivDataTable>
                <StyledDivDataTable>Vendedor</StyledDivDataTable>
                <StyledDivDataTable>Valor</StyledDivDataTable>
                <StyledDivDataTable>Nosso Número</StyledDivDataTable>
                <StyledDivDataTable>Datas</StyledDivDataTable>
                <StyledDivDataTable>Status</StyledDivDataTable>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ height: 600, textAlign: "center" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        position: "relative",
                      }}
                    >
                      <Spinner
                        state={isLoading}
                        size={10}
                        css={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                invoiceSales.data?.content.map((row: any, index: any) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor:
                        index % 2 === 0 ? "white" : theme.COLORS.GRAY6,
                    }}
                  >
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography fontWeight={"bold"}>
                        {row.clientName}
                      </SalesTypography>
                      <SalesTypography>
                        {row.clientCpforcnpj
                          ? formatDocument(row.clientCpforcnpj)
                          : "--"}
                      </SalesTypography>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography fontWeight={"bold"}>
                        {row.sellerName}
                      </SalesTypography>
                      <SalesTypography>
                        {row.sellerCpforcnpj
                          ? formatDocument(row.sellerCpforcnpj)
                          : "--"}
                      </SalesTypography>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography>
                        {formatCurrencyBR(row.value)}
                      </SalesTypography>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography>{row.nossonumero}</SalesTypography>
                    </SalesCell>
                    <SalesCell
                      component="th"
                      scope="row"
                      size="small"
                      width={220}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: "25px",
                          gap: 1,
                        }}
                      >
                        <SalesTypography sx={{ width: "70px" }}>
                          Emissão:
                        </SalesTypography>
                        <SalesTypography fontWeight={"bold"}>
                          {row.issuancedate
                            ? formatarDataSemHoras(row.issuancedate)
                            : "--"}
                        </SalesTypography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: "25px",
                          gap: 1,
                        }}
                      >
                        <SalesTypography sx={{ width: "70px" }}>
                          Vencimento:
                        </SalesTypography>
                        <SalesTypography fontWeight={"bold"}>
                          {row.duedate
                            ? formatarDataSemHoras(row.duedate)
                            : "--"}
                        </SalesTypography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginRight: "25px",
                          gap: 1,
                        }}
                      >
                        <SalesTypography sx={{ width: "70px" }}>
                          Pagamento:
                        </SalesTypography>
                        <SalesTypography fontWeight={"bold"}>
                          {row.paymentdate
                            ? formatarData(row.paymentdate)
                            : "--"}
                        </SalesTypography>
                      </Box>
                    </SalesCell>
                    <SalesCell component="th" scope="row" size="small">
                      <SalesTypography>
                        <StyledStatus status={row.status} />
                      </SalesTypography>
                    </SalesCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        )}
        <DataTablePagination
          setPage={setPageInvoice}
          page={pageInvoice}
          setRowsPerPage={setRowsPerPageInvoice}
          rowsPerPage={rowsPerPageInvoice}
          count={countInvoice}
        />
      </ContentBody>
      <DefaultFilter
        isOpen={isOpen}
        items={items}
        onChangeFilter={setFilterInvoice}
        onClose={onClose}
        onOpen={onOpen}
        title="Filtrar transações"
      />
    </PageContentContainer>
  );
};

export default FinancialBoleto;
