import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HailIcon from "@mui/icons-material/Hail";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DiscountIcon from "@mui/icons-material/Discount";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaymentsIcon from "@mui/icons-material/Payments";
import LinkIcon from "@mui/icons-material/Link";
import GroupIcon from "@mui/icons-material/Group";
import ArticleIcon from "@mui/icons-material/Article";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const drawerItems = [
  {
    icon: DashboardIcon,
    link: "/dashboard",
    label: "Dashboard",
    notAcceptGroup: "CLIENT",
    acceptRoles: ["ROLE_ADMIN"],
  },
  {
    icon: LinkIcon,
    link: "/links",
    label: "Links",
    acceptRoles: [
      "ROLE_ADMIN",
      "ROLE_SELLER",
      "ROLE_CRUD_SELLER",
      "ROLE_FINANCEIRO",
    ],
    notAcceptGroup: "INDICATION",
  },
  {
    icon: MonetizationOnIcon,
    link: "/financeiro",
    label: "Financeiro",
    acceptRoles: ["ROLE_ADMIN"],
    notAcceptGroup: "CLIENT",
  },
  {
    icon: FormatListBulletedIcon,
    link: "/listas",
    label: "Listas",
    acceptRoles: ["ROLE_ADMIN"],
    notAcceptGroup: "CLIENT",
  },
  {
    icon: CampaignIcon,
    link: "/campanhas",
    label: "Campanhas",
    acceptRoles: ["ROLE_ADMIN"],
    notAcceptGroup: "CLIENT",
  },
  {
    icon: ReceiptIcon,
    link: "/vendas",
    label: "Vendas",
    acceptRoles: [
      "ROLE_ADMIN",
      "ROLE_SELLER",
      "ROLE_INDICATIONS",
      "ROLE_CRUD_SELLER",
      "ROLE_CRUD_INDICATIONS",
      "ROLE_FINANCEIRO",
    ],
    notAcceptGroup: "CLIENT",
  },
  {
    icon: HailIcon,
    link: "/vendedores",
    label: "Vendedores",
    acceptRoles: ["ROLE_ADMIN", "ROLE_CRUD_SELLER"],
  },
  {
    icon: GroupIcon,
    link: "/clientes",
    label: "Clientes",
    acceptRoles: ["ROLE_ADMIN"],
  },
  {
    icon: Diversity3Icon,
    link: "/indicacoes",
    label: "Indicacoes",
    acceptRoles: ["ROLE_ADMIN", "ROLE_INDICATIONS", "ROLE_CRUD_INDICATIONS"],
  },
  {
    icon: DiscountIcon,
    link: "/cupons",
    label: "Cupons",
    acceptRoles: ["ROLE_ADMIN", "ROLE_CRUD_SELLER", "ROLE_SELLER"],
    notAcceptGroup: "INDICATION",
  },
  {
    groupCondition: "CLIENT",
    items: [
      {
        icon: PaymentsIcon,
        link: "/pagamentos",
        label: "Pagamentos",
        acceptRoles: ["ROLE_COURSES"],
        notAcceptGroup: "ROLE_ADMIN",
      },
      {
        icon: ArticleIcon,
        link: "/contrato",
        label: "Contrato",
        acceptRoles: ["ROLE_COURSES"],
        notAcceptGroup: "ROLE_ADMIN",
      },
    ],
  },
  {
    icon: OndemandVideoIcon,
    link: "/aulas",
    label: "Aulas",
    acceptRoles: ["ROLE_ADMIN", "ROLE_COURSES", "ROLE_CRUD_COURSES"],
  },
];
