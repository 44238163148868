import { useSaleFormContext } from "core/context/SaleContext";
import { TPixBodyResponse } from "core/models/payment/pix";
import DisplayPayment from "../QR";

const WaitingPayment = () => {
  const { response } = useSaleFormContext();
  return <DisplayPayment {...(response?.pix as TPixBodyResponse)} />;
};

export default WaitingPayment;
