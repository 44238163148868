import { Box, Button } from "@mui/material";
import theme from "core/theme/theme";

import {
    formatCurrencyBR,
    formatDateBr,
    formatDocument,
    removeNonNumeric,
} from "core/utils/globalFunctions";
import html2pdf from "html2pdf.js";
import Image from "images/assets/paraContrato.png";

const monthsNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
];

interface Props {
    dowloadFunction?: boolean;
    clientName: string;
    clientDocument: string;
    clientAddressAsString: string;
    createDate?: string;
}

interface DateInfos {
    day: number;
    month: string;
    year: number;
}

export const NewClearNameDeal997 = ({
    clientDocument,
    clientName,
    clientAddressAsString,
    dowloadFunction,
    createDate,
}: Readonly<Props>) => {
    //? documento limpo
    const cleanDocument = removeNonNumeric(clientDocument);

    // ? pega o dia, nome do mês e ano atual
    function getActualDate(): DateInfos {
        const date = new Date();
        return {
            day: date.getDate(),
            month: monthsNames[date.getMonth()],
            year: date.getFullYear(),
        };
    }

    // ? data atual
    const actualDate: DateInfos = getActualDate();

    // ? função para baixar o pdf
    const downloadPDF = () => {
        const element = document.getElementById("content");
        const opt = {
            margin: 0,
            filename: "contrato.pdf",
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };
        html2pdf().from(element).set(opt).save();
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                background: theme.COLORS.WHITE_100,
            }}
        >
            <div id="content">
                <img src={Image} alt="Logo" style={{ width: "100%", height: "auto" }} />
                <div>
                    <p>
                        <h3 style={{ textAlign: "center", padding: "0 10px", textDecoration: "underline" }}>
                            CONTRATO PARTICULAR DE PRESTAÇÃO DE SERVIÇOS
                        </h3>
                    </p>
                    <p>
                        <b>CONTRATADA: </b>POSITIVO PAYMENTS SERVIÇOS LTDA, inscrita no CNPJ sob o nº 44.341.929/0001-50, com sede
                        na Avenida Presidente Epitácio Pessoa, nº 3701, Bairro Miramar, João Pessoa – PB, CEP 58.032-000, e-mail:
                        contato@positivobra.com.br, doravante denominada "CONTRATADA".
                    </p>
                    <p>
                        <b>CONTRATANTE: </b> {clientName}, inscrita no CPF/CNPJ sob o nº {formatDocument(cleanDocument!)},
                        com endereço em {clientAddressAsString}, doravante denominada "CONTRATANTE".
                    </p>
                    <p>
                        As partes acima identificadas têm entre si justo e acordado o presente Contrato de Prestação de Serviços Administrativos,
                        mediante as seguintes cláusulas e condições:
                    </p>
                    <p>
                        <b>CLÁUSULA PRIMEIRA – OBJETO DO CONTRATO</b>
                    </p>
                    <p>
                        1.1 A Contratada se compromete a prestar serviços de intermediação de soluções administrativas para:
                    </p>
                    <ul style={{ listStyleType: "lower-alpha" }}>
                        <li>Inibição de apontamentos no CPF do Contratante junto aos órgãos de proteção ao crédito (SPC, Serasa e Boa Vista);</li>
                        <li>Restauração de score de crédito;</li>
                        <li>Brinde – cartão de crédito Posicard, com limite garantido, a partir de R$ 500,00 (quinhentos reais).</li>
                    </ul>
                    <p>
                        <b>CLÁUSULA SEGUNDA – REMUNERAÇÃO E FORMA DE PAGAMENTO</b>
                    </p>
                    <p>
                        2.1 Pelos serviços prestados, a CONTRATANTE pagará à CONTRATADA o valor de R$ 997,00 (novecentos e noventa e sete) reais.
                    </p>
                    <p>
                        2.2 O pagamento deverá ser efetuado via transferência bancária para a conta indicada pela CONTRATADA, ou conforme acordado entre as partes.
                    </p>
                    <p>
                        2.3  Em caso de inadimplência por parte da CONTRATANTE, incidirá multa de 2% (dois por cento) sobre o valor devido, acrescida de juros de 1% (um por cento) ao mês.
                    </p>
                    <p>
                        <b>CLÁUSULA TERCEIRA – PRAZO DE EXECUÇÃO</b>
                    </p>
                    <p>
                        3.1 A CONTRATADA compromete-se a executar os serviços no prazo máximo de 60 (sessenta) dias úteis, contados a partir da data de assinatura deste contrato.
                    </p>
                    <p>
                        <b>CLÁUSULA QUARTA – GARANTIA</b>
                    </p>
                    <p>
                        4.1 A CONTRATADA oferece uma garantia de 6 (seis) meses para a manutenção das baixas nas restrições junto aos órgãos de proteção ao crédito, a contar da data da conclusão dos serviços.
                    </p>
                    <p>
                        <b>CLÁUSULA QUINTA – BENEFÍCIOS ADICIONAIS (BRINDE)</b>
                    </p>
                    <p>
                        5.1 A CONTRATANTE terá direito ao cartão Posicard Black, com limite de crédito a partir de R$ 500,00 (quinhentos reais). A anuidade do cartão será de R$ 9,90 (nove reais e noventa centavos) por mês.
                    </p>
                    <p>
                        5.2 A CONTRATANTE terá direito a utilizar uma sala VIP por ano, conforme disponibilidade e regras estabelecidas pela CONTRATADA.
                    </p>
                    <p>
                        5.3 Para cada indicação de novos clientes que resultem na contratação dos serviços da CONTRATADA, a CONTRATANTE receberá um crédito adicional de R$ 100,00 (cem reais) no Posicard Black e terá direito a uma sala VIP adicional.
                    </p>
                    <p>
                        5.4 A CONTRATANTE também terá direito a um atendimento via telemedicina 24 horas como brinde adicional, conforme as regras estabelecidas pela CONTRATADA.
                    </p>
                    <p>
                        5.5 Os benefícios adicionais poderão sofrer alterações, mediante aviso prévio pela CONTRATADA
                    </p>

                    <p><b>CLÁUSULA SEXTA – OBRIGAÇÕES DAS PARTES</b></p>
                    <p><b>6.1 Obrigações da CONTRATADA:</b>  a) Executar os serviços de forma diligente e profissional, observando a legislação vigente; b) Manter a CONTRATANTE informada sobre o andamento dos serviços; c) Garantir a confidencialidade das informações compartilhadas pela CONTRATANTE.
                    </p>
                    <p><b>6.2 Obrigações da CONTRATANTE:</b>  a) Fornecer todas as informações e documentos necessários para a prestação dos serviços; b) Efetuar o pagamento conforme o estipulado no contrato; c) Utilizar os benefícios concedidos de acordo com as condições estabelecidas pela CONTRATADA.</p>

                    <p><b>CLÁUSULA SÉTIMA – LEI GERAL DE PROTEÇÃO DE DADOS (LGPD)</b></p>
                    <p>
                        7.1 As partes concordam em cumprir todas as obrigações impostas pela Lei Geral de Proteção de Dados (Lei nº 13.709/2018), comprometendo-se a
                        tratar os dados pessoais com responsabilidade, segurança e confidencialidade.
                    </p>
                    <p>
                        7.2 A CONTRATADA declara que os dados pessoais coletados durante a execução do contrato serão utilizados exclusivamente para os fins previstos neste instrumento, não podendo ser compartilhados com terceiros sem o
                        consentimento prévio e expresso da CONTRATANTE, salvo em casos de obrigação legal.
                    </p>
                    <p>
                        7.3 Em caso de violação de dados pessoais, a CONTRATADA compromete-se a comunicar a CONTRATANTE em até 72 horas após a identificação do incidente, adotando as medidas necessárias para mitigar eventuais danos.
                    </p>

                    <p><b>CLÁUSULA OITAVA – VIGÊNCIA</b></p>
                    <p>
                        8.1 Este contrato terá vigência de 6 (seis) meses, a contar da manutenção das baixas nas restrições junto aos órgãos de proteção
                        ao crédito, podendo ser renovado por igual período mediante acordo entre as partes.
                    </p>
                    <p><b>CLÁUSULA NONA – RESCISÃO</b></p>
                    <p>
                        9.1 O presente contrato poderá ser rescindido por qualquer das partes mediante aviso prévio por escrito de 7 (sete) dias,
                        ou imediatamente em caso de descumprimento das obrigações contratuais.
                    </p>
                    <p><b>CLÁUSULA DÉCIMA - PREVENÇÃO À LAVAGEM DE DINHEIRO E FINANCIAMENTO AO TERRORISMO</b></p>
                    <p>
                        10.1 A CONTRATADA e seus representantes declaram ter conhecimento das leis que dispõem sobre a prevenção e combate aos crimes de
                        lavagem de dinheiro e de financiamento do terrorismo e das normas complementares editadas pelo Banco Central do Brasil (BCB),
                        Conselho Monetário Nacional (CMN) e Secretaria da Receita Federal (SRF) e, ainda, tem ciência que a CONTRATANTE, por força dessas
                        leis e normas, está obrigada a comunicar às autoridades competentes a ocorrência de operações previstas nas referidas normas.
                    </p>
                    <p>
                        10.2 A CONTRATADA e seus representantes declaram ter conhecimento que a CONTRATANTE não pactua com comportamentos ou atos ilícitos
                        e que possui política organizacional (ANEXO I), com diretrizes preventivas,
                        que prezam pela prevenção à lavagem de dinheiro e ao financiamento do terrorismo, nos termos da Circular nº 3.978/2020 do BCB.
                    </p>
                    <p>
                        10.3 A CONTRATADA declara, por si e por seus administradores (“Representantes”), empregados, colaboradores, contratados, sócios,
                        acionistas, empresas integrantes do seu grupo econômico, que: (i) Atua em conformidade com todas as leis, regulamentações e
                        normas relacionadas ao combate e prevenção à corrupção, à lavagem de dinheiro e ao
                    </p>
                    <p>
                        10.4 A CONTRATADA se compromete a informar à CONTRATANTE caso algum de seus financiamento do terrorismo; (ii) Não realizou,
                        não realiza e não realizará quaisquer atos ou práticas que, direta ou indiretamente, envolvam oferecimento, promessas,
                        suborno, extorsão, autorização, solicitação, aceite, pagamento, entrega ou qualquer outro ato relacionado
                        a vantagem pecuniária indevida ou qualquer outro favorecimento ilegal em desconformidade com a legislação mencionada acima.
                    </p>
                    <p>
                        10.5 Representantes seja pessoa politicamente exposta, conforme definido pela Circular nº 3.978/2020 do BCB ou norma vigente sobre o tema.
                    </p>
                    <p>
                        10.6 O não cumprimento das disposições previstas nesta Cláusula pela CONTRATADA poderá acarretar a rescisão unilateral deste Contrato, pela CONTRATANTE,
                        que poderá automaticamente suspender a execução deste Contrato e/ou resili-lo imediatamente.
                    </p>
                    <p>
                        10.7  A violação desta Cláusula, pela CONTRATADA ou por seus Representantes, ensejará, ainda, a obrigação de indenizar a CONTRATANTE
                        por eventuais perdas e danos, nos termos deste Contrato.
                    </p>
                    <p>
                        10.8 A CONTRATADA se compromete a comunicar imediatamente a CONTRATANTE no caso de ocorrência de qualquer violação,
                        suspeita de violação ou qualquer situação irregular que se apresente contra a legislação aplicável acerca de combate e prevenção à lavagem
                        de dinheiro, ao financiamento do terrorismo e à corrupção, bem como acordos e convenções internacionais que regulamente.
                    </p>
                    <p><b>CLÁUSULA DÉCIMA PRIMEIRA – DA SEGURANÇA CIBERNÉTICA</b></p>
                    <p>
                        11.1 A CONTRATADA e seus representantes declaram ciência de que o a CONTRATANTE possui Política de Segurança Cibernética que prevê a adoção de medidas que busquem assegurar a confidencialidade, a integridade e a disponibilidade dos dados e
                        dos sistemas de informação utilizados no âmbito deste Contrato, nos termos da Resolução CMN nº 4.893/2021, devendo ser dado conhecimento aos seus colaboradores, contratados, parceiros e prestadores de serviço.
                    </p>
                    <p>
                        11.2 A CONTRATADA e seus representantes declaram, ainda, ciência acerca do conteúdo da Política Institucional de Segurança Cibernética da CONTRATANTE, quanto aos objetivos e responsabilidades e compromete-se no exercício das atividades,
                        prestação de serviços ou fornecimento de bens ou produtos objeto deste Contrato, adotar procedimentos e controles compatíveis aos utilizados pelas entidades.
                    </p>
                    <p>
                        11.3 Em caso da prestação de serviços e/ou fornecimento de bens e produtos objeto deste Contrato envolver serviços de processamento e armazenamento de dados e de computação em nuvem,
                        manuseio de dados ou informações sensíveis e que sejam relevantes para a condução das atividades da CONTRATANTE, as partes concordam em executar procedimentos e controles que visem prevenir,
                        detectar e reduzir vulnerabilidades e impactos relacionados aos incidentes cibernéticos, nos termos previsto na Política Institucional de Segurança Cibernética. Dentre os procedimentos que deverão ser
                        adotados pelas Partes, estão: a) Autenticação e criptografia; b) prevenção e a detecção de intrusão; c) prevenção de vazamento de informações; d) realização periódica de testes e varreduras para detecção de
                        vulnerabilidades; e) proteção contra softwares maliciosos; f) estabelecimento de mecanismos de rastreabilidade e controles de acesso e de segmentação da rede de computadores; g) manutenção de cópias
                        de segurança dos dados e das informações; h) a identificação daqueles que têm acesso às informações da CONTRATANTE deve ser única, pessoal e intransferível, qualificando-o como responsável pelas ações
                        realizadas; i) A concessão de acesso às informações e recursos de informação somente imprescindíveis para o pleno desempenho das atividades objeto deste Contrato. Ainda, caso o objeto do contrato envolva
                        acesso ao ambiente e rede tecnológica da CONTRATANTE, o acesso deverá se restringir a necessidade para execução dos serviços, bem como deverá atender aos requisitos de segurança contidos na Política
                        Institucional de Segurança da Informação e na Política Institucional de Segurança Cibernética. a) termos firmados deste contrato; b) documentação e informações referentes aos serviços prestados;
                        e c) dependências da CONTRATADA.
                    </p>
                    <p>
                        11.4 O não cumprimento das disposições previstas nesta Cláusula pela CONTRATADA poderá acarretar a rescisão unilateral deste Contrato pela CONTRATANTE, que poderá automaticamente suspender a execução
                        deste Contrato e/ou resili-lo imediatamente.
                    </p>
                    <p>
                        11.5 A violação desta Cláusula, pela CONTRATADA ou por seus Representantes, ensejará, ainda, a obrigação de indenizar a CONTRATANTE por eventuais perdas e danos, nos termos deste Contrato.
                    </p>
                    <p>
                        11.6 A CONTRATADA se compromete a comunicar imediatamente a CONTRATANTE no caso de ocorrência de quaisquer incidentes de segurança cibernética, violação, suspeita de violação ou qualquer situação
                        irregular que se apresente contra a legislação aplicável acerca da Segurança Cibernética.
                    </p>
                    <p><b>CLÁUSULA DÉCIMA SEGUNDA - DO COMPROMISSO ANTICORRUPÇÃO DO PACTO DE ÉTICA E DA EXPLORAÇÃO DE MÃO DE OBRA EM CONDIÇÕES ANÁLOGAS A DE TRABALHO ESCRAVO CLÁUSULA DÉCIMA TERCEIRA </b></p>
                    <p>
                        12.1 As PARTES assumem o compromisso de não praticar qualquer ato que atente: a) contra o patrimônio público nacional e/ou estrangeiro; b) contra princípios da administração pública ou; c)
                        contra os compromissos internacionais assumidos pelo Brasil, conforme disposto na Lei nº 12.846/2013, que dispõe sobre a responsabilização administrativa e civil de pessoas jurídicas pela prática de
                        atos contra a administração pública, nacional e/ou estrangeira. As partes declaram ciência e concordância com o Pacto de Ética do CONTRATANTE, em especial as seguintes premissas: a) observância de critérios
                        técnicos, profissionais, éticos, não ensejando favorecimento de qualquer natureza; b) idoneidade, imparcialidade,
                        transparência e ética; e c) cumprimento das exigências legais, em especial nos aspectos tributários, trabalhistas e previdenciários.
                    </p>
                    <p>
                        12.2 Sem prejuízo do disposto acima, não será admitida a exploração de mão de obra em condições análogas à de trabalho escravo e/ou trabalho infantil em qualquer nível da cadeia de suprimentos.
                    </p>
                    <p>
                        12.3  O descumprimento dos itens dispostos acima implicará na imediata rescisão do presente contrato, independentemente de notificação, sem prejuízo de eventual indenização cabível, à PARTE prejudicada,
                        bem como por eventuais perdas ou danos.
                    </p>
                    <p><b>CLÁUSULA DÉCIMA TERCEIRA - DA RESPONSABILIDADE SOCIOAMBIENTAL </b></p>
                    <p>
                        13.1 Em referência às ações socioambientais da CONTRATANTE que são resultado do empenho na prevenção e no gerenciamento de riscos e impactos socioambientais, a CONTRATADA se
                        compromete a proteger e preservar o meio ambiente, bem como evitar quaisquer práticas que possam lhe causar danos, executando seus serviços em estrita observância das normais
                        legais e regulamentares, federais, estaduais ou municipais que disciplinam, incentivam e promovem o engajamento sustentável.
                    </p>
                    <p><b>DO FORO CLÁUSULA DÉCIMA QUARTA</b></p>
                    <p>
                        14.1 Os casos omissos ou dúvidas que surgirem serão dirimidas na forma da legislação aplicável, ficando eleito o foro da Comarca de João Pessoa - Paraíba, para qualquer ação fundada neste contrato,
                        renunciando-se a qualquer outro, por muito especial que seja.
                    </p>
                    <p>
                        E por estarem de pleno acordo, as partes assinam o presente instrumento em duas vias de igual teor e forma, na presença de duas testemunhas.
                    </p>
                    <p><b>João Pessoa – PB, {createDate
                        ? formatDateBr(createDate)
                        : `${actualDate.day} de ${actualDate.month} de 2024`}
                        .</b></p>
                    <p style={{ textAlign: "center" }}>________________________________________________________________________________</p>
                    <p style={{ textAlign: "center" }}><b>POSITIVO PAYMENTS SERVIÇOS LTDA</b></p>
                    <p style={{ textAlign: "center" }}>________________________________________________________________________________</p>
                    <p style={{ textAlign: "center" }}><b>{clientName}</b></p>
                    <p><b>Testemunhas.</b></p>
                    <p>
                        <span style={{ display: "block" }}>Nome:</span>
                        <span style={{ display: "block" }}>CPF:</span>
                    </p>
                    <p style={{ textAlign: "center" }}>1. _____________________________________________________________________________________________________________</p>
                    <p>
                        <span style={{ display: "block" }}>Nome:</span>
                        <span style={{ display: "block" }}>CPF:</span>
                    </p >
                    <p style={{ textAlign: "center" }}>1. _____________________________________________________________________________________________________________</p>

                </div >
            </div >
            {dowloadFunction && (
                <Button sx={{ margin: "auto" }} onClick={downloadPDF}>
                    Download PDF
                </Button>
            )}
        </div >
    );
};

