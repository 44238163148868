import {
  Box,
  IconButton,
  InputAdornment,
  InputProps,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import GenericTextField from "app/components/genericTextField/GenericTextField";
import { useSaleFormContext } from "core/context/SaleContext";
import { useState } from "react";
import styled from "styled-components";
import theme from "core/theme/theme";
import { IFormField } from "app/components/sale/clientBlockForm";

/* 
    Este componente é um formulário para os dados pessoais do cliente
    Caso o cliente já seja registrado ele nem verá essa tela
*/

const ClientPersonalInfos = () => {
  const { formik } = useSaleFormContext();

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  const personalInfos: IFormField[] = [
    { name: "cpforcnpj", label: "CPF/CNPJ" },
    { name: "name", label: "Nome" },
    { name: "email", label: "E-mail" },
    { name: "telephone", label: "Telefone" },
  ];

  const optional: IFormField[] = [{ name: "cameThrough", label: "Veio por" }];

  return (
    <StyledContainer mobile={isSmallScreen}>
      {/* Coluna 1: Informações Pessoais */}
      <ColumnContainer>
        <ColumnTitle>Informações Pessoais</ColumnTitle>
        {personalInfos.map((field) => (
          <GenericTextField<string>
            key={field.name}
            error={
              !!(formik.errors as Record<string, string | undefined>)[
                field.name
              ]
            }
            helperText={
              (formik.errors as Record<string, string | undefined>)[field.name]
            }
            name={field.name}
            label={field.label}
            value={formik.values[field.name]}
            props={{
              onChange: formik.handleChange,
              disabled: field.name === "cpforcnpj" ? true : undefined,
            }}
          />
        ))}
      </ColumnContainer>
      {/* Coluna 2: Informações de Login */}
      <ColumnContainer>
        <ColumnContainer>
          <ColumnTitle>Informações Opcionais</ColumnTitle>
          {optional.map((field) => (
            <GenericTextField<string>
              key={field.name}
              error={
                !!(formik.errors as Record<string, string | undefined>)[
                  field.name
                ]
              }
              helperText={
                (formik.errors as Record<string, string | undefined>)[
                  field.name
                ]
              }
              name={field.name}
              label={field.label}
              value={formik.values[field.name]}
              props={{
                onChange: formik.handleChange,
              }}
            />
          ))}
        </ColumnContainer>
      </ColumnContainer>
    </StyledContainer>
  );
};

export default ClientPersonalInfos;

const StyledContainer = styled(Box)<{ mobile?: boolean }>`
  display: flex;
  gap: 1rem;

  ${({ mobile }) =>
    mobile
      ? `
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
      `
      : `
        flex: 0.5;
        flex-direction: row;
        margin: auto;
  `}
`;

const ColumnContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ColumnTitle = styled(Typography)`
  color: ${theme.COLORS.BLUE3} !important;
  font-size: 18 !important;
  font-weight: bold !important;
`;
