import { Box, Divider, useMediaQuery, useTheme } from "@mui/material";

import { LoginContainer, LoginContent, LoginLogo } from "../styles";
import Logo from "images/assets/logo2.png";

interface LoginPageLayoutProps {
  children: React.ReactNode;
}

const LoginPageLayout = ({ children }: Readonly<LoginPageLayoutProps>) => {
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        height: "100svh",
        width: "100svw",
        display: "flex",
      }}
    >
      <LoginContainer mobile={isSmallScreen}>
        <LoginContent mobile={isSmallScreen}>
          <LoginLogo src={Logo} alt="Logo" mobile={isSmallScreen} />
        </LoginContent>
        {!isSmallScreen && <Divider orientation="vertical" flexItem />}
        <LoginContent mobile={isSmallScreen}>{children}</LoginContent>
      </LoginContainer>
    </Box>
  );
};

export default LoginPageLayout;
