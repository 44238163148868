import { Box, useMediaQuery, useTheme } from "@mui/material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import copy from "clipboard-copy";
import { useState } from "react";

import { useVerifyAccess } from "core/hooks/authentication/useVerifyAcess";
import DefaultModal from "app/components/modals/defaultModal/defaultModal";
import GenerateLink from "./generateLink";
import LinkDefault from "./linkDefault";
import LinkCustom from "./linkCustom";
import { InnerBox, Title } from "./styles";
import { useModalManager } from "./hooks/useModalManager";
import { useLinkManager } from "./hooks/useLinkManager";
import { useLinkRequestManager } from "./hooks/useLinkRequestsManager";
import { useLinkGenerator } from "./hooks/useLinkGenerator";
import { LINK_VENDA_CURSO, LINK_VENDA_LIMPA_NOME } from "core/utils/constants";

const Links = () => {
  const navigate = useNavigate();

  const modalManagers = useModalManager();
  const linkManagers = useLinkManager();
  const requestManagers = useLinkRequestManager();
  const currentTheme = useTheme();
  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  const { handleGenerateLink } = useLinkGenerator(
    { ...linkManagers, ...requestManagers },
    modalManagers
  );

  const [indicationNameWithCupom, setIndicationNameWithCupom] = useState("");
  const [cupomName, setCupomName] = useState("");
  const [cupomNameWithIndication, setCupomNameWithIndication] = useState("");
  const [indicationNameConsultoria, setIndicationNameConsultoria] =
    useState("");

  // Verifica se o usuário tem acesso a essa página
  const hasAccess = useVerifyAccess({
    allowedRoles: [
      "ROLE_ADMIN",
      "ROLE_SELLER",
      "ROLE_CRUD_SELLER",
      "ROLE_FINANCEIRO",
    ],
    restrictedGroup: "INDICATION",
  });

  // Se o usuário não tiver acesso, redireciona para a página anterior
  if (!hasAccess) {
    navigate(-1);
    return null;
  }

  return (
    <Container mobile={isSmallScreen}>
      {/* Links para consultoria */}
      <InnerBox>
        <Title>Links consultoria</Title>
        <LinkDefault linkType="consultoria" />
        <LinkCustom
          title="Link com indicação"
          typeLink="/consultoria"
          link={linkManagers.linkConsultoria}
          baseUrl={LINK_VENDA_CURSO}
          chipLabels={
            indicationNameConsultoria ? [indicationNameConsultoria] : []
          }
          isLinkGenerated={linkManagers.linkConsultoria !== ""}
          onAddLink={() =>
            modalManagers.setModalLinkIndicationConsultoria(true)
          }
          onClearLink={() => {
            linkManagers.setLinkConsultoria("");
            setIndicationNameConsultoria("");
          }}
          onCopyLink={() =>
            copy(`${LINK_VENDA_CURSO}${linkManagers.linkConsultoria}`)
          }
        />
      </InnerBox>
      {/* Links limpa nome */}
      <InnerBox>
        <Title>Links limpa nome</Title>
        <LinkDefault linkType="limpaNome" />
        <LinkCustom
          title="Link com cupom"
          typeLink="/limpaNome"
          link={linkManagers.linkCupom}
          baseUrl={LINK_VENDA_LIMPA_NOME}
          chipLabels={cupomName ? [cupomName] : []}
          isLinkGenerated={linkManagers.linkCupom !== ""}
          onAddLink={() => modalManagers.setModalLinkCupom(true)}
          onClearLink={() => {
            linkManagers.setLinkCupom("");
            setCupomName("");
          }}
          onCopyLink={() =>
            copy(`${LINK_VENDA_LIMPA_NOME}${linkManagers.linkCupom}`)
          }
        />
        <LinkCustom
          title="Link com indicação e cupom"
          typeLink="/limpaNome"
          link={linkManagers.linkCupomAndIndication}
          baseUrl={LINK_VENDA_LIMPA_NOME}
          chipLabels={
            cupomNameWithIndication || indicationNameWithCupom
              ? [cupomNameWithIndication, indicationNameWithCupom]
              : []
          }
          isLinkGenerated={linkManagers.linkCupomAndIndication !== ""}
          onAddLink={() => modalManagers.setModalLinkCupomIndication(true)}
          onClearLink={() => {
            linkManagers.setLinkCupomAndIndication("");
            setCupomNameWithIndication("");
            setIndicationNameWithCupom("");
          }}
          onCopyLink={() =>
            copy(
              `${LINK_VENDA_LIMPA_NOME}${linkManagers.linkCupomAndIndication}`
            )
          }
        />
      </InnerBox>
      {/* gerar link com cupom sem indicacao */}
      <DefaultModal
        title="Link por cupom"
        isOpen={modalManagers.modalLinkCupom}
        onOpen={() => modalManagers.setModalLinkCupom(true)}
        onClose={() => {
          modalManagers.setModalLinkCupom(false);
          setCupomName("");
        }}
      >
        <GenerateLink
          saleType={"limpaNome"}
          payload={requestManagers.linkRequestLimpaNomeOnlyCupom}
          setPayload={requestManagers.setLinkRequestLimpaNomeOnlyCupom}
          onSubmit={() => handleGenerateLink(false, "limpaNome")}
          onlyCupom={true}
          setCouponName={setCupomName}
        />
      </DefaultModal>
      {/* gerar link com indicação ou cupom */}
      <DefaultModal
        title="Link por indicação e/ou cupom"
        isOpen={modalManagers.modalLinkCupomIndication}
        onOpen={() => modalManagers.setModalLinkCupomIndication(true)}
        onClose={() => {
          modalManagers.setModalLinkCupomIndication(false);
          setCupomNameWithIndication("");
          setIndicationNameWithCupom("");
        }}
      >
        <GenerateLink
          payload={requestManagers.linkRequest}
          setPayload={requestManagers.setLinkRequest}
          onSubmit={() => handleGenerateLink(true, "limpaNome")}
          setIndicationName={setIndicationNameWithCupom}
          saleType={"limpaNome"}
          setCouponName={setCupomNameWithIndication}
        />
      </DefaultModal>
      {/* gerar link com indicação (consultoria) */}
      <DefaultModal
        title="Link por indicação"
        isOpen={modalManagers.modalLinkIndicationConsultoria}
        onOpen={() => modalManagers.setModalLinkIndicationConsultoria(true)}
        onClose={() => {
          setIndicationNameConsultoria("");
          modalManagers.setModalLinkIndicationConsultoria(false);
        }}
      >
        <GenerateLink
          payload={requestManagers.linkRequestOnlyIndication}
          setPayload={requestManagers.setLinkRequestOnlyIndication}
          onSubmit={() => handleGenerateLink(true, "consultoria")}
          setIndicationName={setIndicationNameConsultoria}
          saleType={"consultoria"}
        />
      </DefaultModal>
    </Container>
  );
};

export default Links;

const Container = styled(Box)<{ mobile?: boolean }>`
  ${(props) =>
    props.mobile
      ? `
    height: 90svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  `
      : `
    height: 90svh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    padding-top: 2%;
    gap: 16px;
  `}
`;
