import { useEffect } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "core/hooks/reduxHooks";
import { resetQrCodeData } from "core/redux/slices/authSlice";
import { RootState } from "core/redux/store";
import WaitingPayment from "./content/waitingPayment";
import LoginPageLayout from "./content/loginPageLayout";
import Authentication from "./content/authentication";

const Login = () => {
  const qrCodeData = useSelector((state: RootState) => state.auth.qrCodeData);
  const dispatch = useAppDispatch();

  // Resetar o qrCodeData ao carregar ou recarregar a página
  useEffect(() => {
    dispatch(resetQrCodeData());
  }, [dispatch]);

  return (
    <LoginPageLayout>
      {qrCodeData ? <WaitingPayment /> : <Authentication />}
    </LoginPageLayout>
  );
};

export default Login;
