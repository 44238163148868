import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import QRCode from "react-qr-code";
import copy from "clipboard-copy";
import Countdown from "react-countdown";
import {
  AppGalleryButton,
  AppStoreButton,
  ButtonsContainer,
  GooglePlayButton,
} from "react-mobile-app-button";

import { TPixBodyResponse } from "core/models/payment/pix";
import { axiosInstance } from "core/api/axios/axiosInstance";
import { SALE } from "core/utils/constants";
import { Notification } from "app/components/toastNotification/toastNotification";
import theme from "core/theme/theme";
import "./styles.css";

const DisplayPayment = (paymentInfo: TPixBodyResponse) => {
  const [isClicked, setIsClicked] = useState(false);
  const [payed, setPayed] = useState(false);
  const [counter, setCounter] = useState(10);

  const APKUrl =
    "https://play.google.com/store/apps/details?id=com.positivo.posicard&hl=pt_BR";
  const IOSUrl = "https://apps.apple.com/br/app/posicard/id6483003777";

  const materialTheme = useTheme();
  const isSmallScreen = useMediaQuery(materialTheme.breakpoints.down("sm"));

  // UseRef para armazenar a data de expiração
  const expirationTime = useRef(Date.now() + 600000);

  const handleCopyClick = async () => {
    try {
      setIsClicked(true);
      await copy(paymentInfo.brcode);
      setTimeout(() => {
        setIsClicked(false);
      }, 500);
    } catch (err) {
      console.error("Erro ao copiar o texto:", err);
    }
  };

  const checkPaymentStatus = () => {
    axiosInstance.get(SALE + "/pix/" + paymentInfo.id).then((resp) => {
      if (resp.data.data.isPayment === true) {
        Notification("Pagamento concluído", "success");
        setPayed(true);
        // window.location.pathname = "/login";
      } else {
        Notification("Aguardando pagamento", "warning");
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
      if (counter === 0 && !payed) {
        checkPaymentStatus();
        setCounter(10);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        height: isSmallScreen ? "85svh" : undefined,
        gap: 5,
      }}
    >
      <Typography
        sx={{
          color: theme.COLORS.WHITE,
          fontWeight: "bolder",
          fontSize: "23px",
          textAlign: "center",
          width: isSmallScreen ? "100%" : "280px",
        }}
      >
        {!payed
          ? "Não perca tempo! Efetue o pagamento e aproveite a promoção enquanto está disponível!"
          : "Pagamento confirmado! Seus apontamentos serão removidos em até 45 dias. Cadastre-se no nosso app do Posicard e aproveite todas as vantagens do seu cartão de crédito."}
      </Typography>
      {!isSmallScreen && !payed && (
        <QRCodeContainer>
          <QRCode value={paymentInfo.brcode} />
        </QRCodeContainer>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 300,
          justifyContent: "space-around",
          gap: 2,
        }}
      >
        {!payed ? (
          <>
            <CounterContainer>
              <CounterTitle>Sua Chave PIX Expira em:</CounterTitle>
              <Countdown
                date={expirationTime.current}
                renderer={({ minutes, seconds, completed }) => {
                  if (completed) {
                    return;
                  } else {
                    return (
                      <div className="timer-container">
                        <div className="timer">
                          <span className="timer-value">
                            {String(minutes).padStart(2, "0")}
                          </span>
                          <span className="timer-label">Minutos</span>
                        </div>
                        <div className="timer">
                          <span className="timer-value">
                            {String(seconds).padStart(2, "0")}
                          </span>
                          <span className="timer-label">Segundos</span>
                        </div>
                      </div>
                    );
                  }
                }}
              />
            </CounterContainer>
            <Paper
              sx={{
                height: 100,
                width: "100%",
                overflowWrap: "break-word",
                overflowY: "scroll",
                padding: 1,
                border: "2px solid #000",
              }}
            >
              {paymentInfo.brcode}
            </Paper>
            <StyledButton isClicked={isClicked} onClick={handleCopyClick}>
              {isClicked ? "Pix copiado" : "Copiar pix"}
            </StyledButton>
          </>
        ) : (
          <>
            <Button
              sx={{
                background: "#fff",
                color: theme.COLORS.BLUE3,
                fontWeight: "bold",
              }}
              onClick={() => (window.location.pathname = "/login")}
            >
              Ir para o login
            </Button>
            <ButtonsContainer>
              <GooglePlayButton
                url={
                  "https://play.google.com/store/apps/details?id=com.positivo.posicard&hl=pt_BR"
                }
                theme={"dark"}
                className={"custom-style"}
                width={250}
                height={80}
                title="Posicard"
              />

              <AppStoreButton
                url={"https://apps.apple.com/br/app/posicard/id6483003777"}
                theme={"dark"}
                className={"custom-style"}
                width={250}
                height={80}
                title="Posicard"
              />
            </ButtonsContainer>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DisplayPayment;

export const StyledName = styled(Typography)`
  font-weight: bold !important;
  font-size: 17px !important;
  color: ${({ theme }) => theme.COLORS.BLUE3} !important;
`;


export const StyledLogo = styled.img`
  width: 35px;
  height: 35px;
`;

export const StyledInfo = styled(Typography)`
  font-size: 17px !important;
  color: ${({ theme }) => theme.COLORS.BLUE3} !important;
`;

const QRCodeContainer = styled(Box)`
  display: inline-block;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
  transform: translateY(-2px);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08);
  }
`;

const fadeToGreen = keyframes`
  0% {
    background-color: ${theme.COLORS.WHITE};
    color: ${theme.COLORS.BLUE3};
  }
  100% {
    background-color: #569C00;
    color: ${theme.COLORS.WHITE};
  }
`;

const StyledButton = styled.button<{ isClicked: boolean }>`
width: 100%;
  background-color: ${({ isClicked }) =>
    isClicked ? "#569C00" : theme.COLORS.WHITE};
  color: ${({ isClicked }) =>
    isClicked ? theme.COLORS.WHITE : theme.COLORS.BLUE3};
  padding: 0.8rem 1.2rem;
  border: 2px solid
    ${({ isClicked }) => (isClicked ? "#569C00" : theme.COLORS.BLUE3)};
  border-radius: 8px;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  animation: ${({ isClicked }) => (isClicked ? fadeToGreen : "none")} 0.3s
    ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.COLORS.WHITE};
    color: ${theme.COLORS.BLUE3};
  }

  &:active {
    transform: scale(0.95);
  }
`;

const CounterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
`;
const CounterTitle = styled(Typography)`
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: ${theme.COLORS.WHITE};
`;
