import { useCallback } from "react";

import { useSaleFormContext } from "core/context/SaleContext";
import { CouponService } from "core/api/coupons/couponService";
import { removeNonNumeric } from "core/utils/globalFunctions";
import { Notification } from "app/components/toastNotification/toastNotification";
import { useSaleUrl } from "./useSaleUrl";

/* 
     Hook que valida o cupom inserido no formulário de venda
    
     Caso não tenha cupom, avança para a próxima etapa
     Caso tenha cupom, valida o cupom e avança para a próxima etapa
*/

const useValidateCoupon = () => {
  const {
    cupom,
    formik,
    setTempCupom,
    setActiveStep,
    activeStep,
    setPaymentInfo,
  } = useSaleFormContext();
  const { cupomId } = useSaleUrl();

  const validateCoupon = useCallback(async () => {
    // resetar a forma de pagamento caso volte
    setPaymentInfo({
      typePayment: "",
      installments: undefined,
      isFees: false,
    });

    if (!cupom) {
      // Caso não tenha cupom, avança para a próxima etapa
      if (!cupomId) {
        setTempCupom({} as any);
        setActiveStep(activeStep + 1);
        return true;
      }
      setActiveStep(activeStep + 1);
      return true;
    }

    try {
      const response = await CouponService.validateCoupon(
        cupom,
        removeNonNumeric(formik.values.cpforcnpj) ?? ""
      );

      Notification("Cupom validado", "success");
      setTempCupom(response.data.data);
      setActiveStep(activeStep + 1);
      return true;
    } catch (err: any) {
      Notification(
        err.response?.data?.errors[0] || "Erro ao validar cupom",
        "error"
      );
      return false;
    }
  }, [cupom, formik, setTempCupom, setActiveStep, activeStep]);

  return { validateCoupon };
};

export default useValidateCoupon;
