import { Step, StepIconProps, StepLabel } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  StepContent,
  StepContentText,
  StepNumber,
} from "app/components/stepper/style";
import theme from "core/theme/theme";
import styled from "styled-components";

/*
    Este componente é utilizado como uma etapa do stepper
    Aqui é configurado a forma do step, o seu label e se ele foi completo ou não

    getClipPath: função que retorna a forma do step

    ColorlibStepIconRoot: componente que estiliza o step de acordo com o seu estado
*/

interface StepProp extends StepIconProps {
  index: number;
  stepsConfig: any;
}

const getClipPath = (index: number, totalSteps: number) => {
  if (index === 0) {
    return "polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)";
  }
  if (index === totalSteps - 1) {
    return "polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%)";
  }
  return "polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%)";
};

const ColorlibStepIconRoot = styled("div")<{
  ownerState: {
    completed?: boolean;
    active?: boolean;
    index: number;
    totalSteps: number;
  };
}>(({ ownerState }) => {
  const { active, completed, index, totalSteps } = ownerState;

  return {
    position: "relative",
    width: 170,
    height: 70,
    background: completed ? theme.COLORS.GRAY5 : theme.COLORS.WHITE,
    display: "flex",
    alignItems: "center",
    clipPath: getClipPath(index, totalSteps),
    ...(active && {
      background: theme.COLORS.BLUE3,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
  };
});

export function CustomStepLabel(props: Readonly<StepProp>) {
  const { active, completed, className, icon, stepsConfig } = props;
  const stepIndex = Number(icon) - 1;

  // Validação de índice
  if (stepIndex < 0 || stepIndex >= stepsConfig.length) {
    console.error(`Invalid step index: ${icon}`);
    return null;
  }

  const step = stepsConfig[stepIndex];

  return (
    <Step>
      <StepLabel
        StepIconComponent={() => (
          <ColorlibStepIconRoot
            ownerState={{
              completed,
              active,
              index: stepIndex,
              totalSteps: stepsConfig.length,
            }}
            className={className}
          >
            <StepContent>
              {!completed ? (
                <StepNumber active={active} completed={completed}>
                  0{stepIndex + 1}
                </StepNumber>
              ) : (
                <CheckCircleIcon style={{ color: theme.COLORS.BLUE3 }} />
              )}
              <StepContentText active={active} completed={completed}>
                {step.label}
              </StepContentText>
            </StepContent>
          </ColorlibStepIconRoot>
        )}
      />
    </Step>
  );
}
