import {
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import { Validations } from "core/utils/validations";
import { formatDocument, removeNonNumeric } from "core/utils/globalFunctions";
import GenericTextField from "app/components/genericTextField/GenericTextField";
import { sendMessegeService } from "core/api/user/userService";
import theme from "core/theme/theme";
import LoginPageLayout from "../content/loginPageLayout";
import { LinkText, LoginTitle } from "../styles";
import ButtonNeo from "app/components/Button/buttonNeo";

type TForgotPassword = {
  login: string;
};

const ForgotPassword = () => {
  const currentTheme = useTheme();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(currentTheme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);

  const initialValues: TForgotPassword = {
    login: "",
  };

  const submitUserToResetPassword = useCallback(async (login: string) => {
    setIsLoading(true);
    await sendMessegeService(login)
      .then(() => {
        setIsLoading(false);
        navigate("/login");
      })
      .catch(() => setIsLoading(false));
  }, []);

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema: Validations.forgotPasswordSchema,
    onSubmit: () => {
      let loginCleaned = removeNonNumeric(formik.values.login);
      localStorage.setItem("redefineKey", loginCleaned!);
      submitUserToResetPassword(String(loginCleaned));
    },
  });

  return (
    <LoginPageLayout>
      <>
        <LoginTitle mobile={isSmallScreen}>Recuperar senha</LoginTitle>
        <Typography
          sx={{
            fontWeight: "bold",
            color: theme.COLORS.MAIN_BLUE,
            fontSize: "1pc",
          }}
        >
          Será enviado um link para o email de cadastro, por favor acessar link.
        </Typography>
        <GenericTextField<string>
          props={{
            fullWidth: true,
            onChange: formik.handleChange,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineIcon />
                </InputAdornment>
              ),
            },
          }}
          onBlur={() => {
            formik.setFieldValue("login", formatDocument(formik.values.login));
          }}
          value={formik.values.login}
          label="CPF/CNPJ"
          name="login"
          error={!!formik.errors.login}
          helperText={formik.errors.login}
        />

        <LinkText to="/login"> Lembrei minha senha</LinkText>
        <ButtonNeo
          isLoading={isLoading}
          activityColor={theme.COLORS.WHITE}
          onClick={() => formik.handleSubmit()}
          style={{
            color: theme.COLORS.WHITE,
          }}
        >
          Enviar
        </ButtonNeo>
        <Typography>V 2.5</Typography>
      </>
    </LoginPageLayout>
  );
};

export default ForgotPassword;
