import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import styled, { keyframes } from "styled-components";
import theme from "core/theme/theme";

// ! pagamento
export const PaymentContainer = styled(Box)<{ mobile: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ mobile }) =>
    !mobile &&
    `
       max-height: 450px;
       overflow-y: auto;
       overflow-x: hidden;
   `}
`;

// ! login
export const LoginLogo = styled.img<{ mobile: boolean }>`
  display: block;
  margin: auto;
  ${({ mobile }) =>
    mobile
      ? `
         width: 200px;
         height: 90px;
        `
      : `
         width: 320px;
         height: 150px;
        `}
`;

export const LoginContainer = styled(Box)<{ mobile: boolean }>`
  display: flex;
  ${({ mobile }) =>
    mobile
      ? `
          flex: 1;
          flex-direction: column;
          max-width: 900px;
          max-height: 500px;
          margin: 0 auto;
        `
      : `
         min-width: 1000px;
         min-height: 500px;
         margin: auto;
         background-color: ${theme.COLORS.WHITE};
         border-radius: 8px;
         border: 2px solid ${theme.COLORS.GRAY6};
        `}
`;

export const LoginContent = styled(Box)<{ mobile: boolean }>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  text-align: center;
  ${({ mobile }) =>
    !mobile &&
    `
        margin: auto;
        max-width: 400px;
        flex: 1;
   `}
`;

export const LoginTitle = styled(Typography)<{ mobile?: boolean }>`
  font-size: 1.3pc !important;
  font-weight: bold !important;
  color: ${theme.COLORS.MAIN_BLUE};
  ${({ mobile }) =>
    !mobile &&
    `
      font-size: 1.1pc !important;
   `}
`;

export const LinkText = styled(Link)`
  font-size: 0.9pc !important;
  font-weight: bold !important;
  color: ${theme.COLORS.MAIN_BLUE};
  text-decoration: underline;
  text-align: end;
`;

// ! Pagamento
export const QRCodeContainer = styled(Box)`
  padding: 0.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
  transform: translateY(-2px);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08);
  }
`;

export const fadeToGreen = keyframes`
  0% {
    background-color: ${theme.COLORS.WHITE};
    color: ${theme.COLORS.BLUE3};
  }
  100% {
    background-color: #569C00;
    color: ${theme.COLORS.WHITE};
  }
`;

export const StyledButton = styled.button<{ isClicked: boolean }>`
  background-color: ${({ isClicked }) =>
    isClicked ? "#569C00" : theme.COLORS.WHITE};
  color: ${({ isClicked }) =>
    isClicked ? theme.COLORS.WHITE : theme.COLORS.BLUE3};
  padding: 0.8rem 1.2rem;
  border: 2px solid
    ${({ isClicked }) => (isClicked ? "#569C00" : theme.COLORS.BLUE3)};
  border-radius: 8px;
  font-weight: bold;
  width: 80%;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  animation: ${({ isClicked }) => (isClicked ? fadeToGreen : "none")} 0.3s
    ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${theme.COLORS.WHITE};
    color: ${theme.COLORS.BLUE3};
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const CounterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
`;
export const CounterTitle = styled(Typography)`
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: ${theme.COLORS.BLUE2};
`;
