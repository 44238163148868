import { Box, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";

import {
  ChipsContainer,
  InnerSection,
  LinkText,
  Section,
  StyledChip,
} from "./styles";

type LinkSectionProps = {
  title: string;
  chipLabels?: string[];
  link?: string;
  baseUrl: string;
  isLinkGenerated: boolean;
  typeLink: string;
  onClearLink: () => void;
  onCopyLink: () => void;
  onAddLink: () => void;
};

const LinkCustom = ({
  title,
  chipLabels = [],
  link,
  baseUrl,
  isLinkGenerated,
  onClearLink,
  onCopyLink,
  onAddLink,
  typeLink,
}: Readonly<LinkSectionProps>) => {
  return (
    <Section>
      <Typography>{title}</Typography>

      {chipLabels.length > 0 && (
        <ChipsContainer>
          {chipLabels.map(
            (label, index) =>
              label && <StyledChip key={index} label={label} size="small" />
          )}
        </ChipsContainer>
      )}

      <InnerSection>
        {isLinkGenerated ? (
          <Link target="_blank" to={`${typeLink}/${link}`}>
            <LinkText>{`${baseUrl}${link}`}</LinkText>
          </Link>
        ) : (
          "* Link não gerado"
        )}
        <Box sx={{ display: "flex" }}>
          {isLinkGenerated && (
            <IconButton onClick={onClearLink}>
              <DeleteIcon color="primary" />
            </IconButton>
          )}

          <IconButton
            disabled={link === "" && isLinkGenerated}
            onClick={isLinkGenerated ? onCopyLink : onAddLink}
          >
            {isLinkGenerated ? (
              <ContentCopyIcon color="primary" />
            ) : (
              <AddBoxIcon color="primary" />
            )}
          </IconButton>
        </Box>
      </InnerSection>
    </Section>
  );
};

export default LinkCustom;
