import { useSaleFormContext } from "core/context/SaleContext";
import { useSaleUrl } from "./useSaleUrl";
import {
  removeExtraSpaces,
  removeNonNumeric,
} from "core/utils/globalFunctions";
import { TNewClientBodyRequest } from "core/models/client";
import { Compra, TSaleBodyRequest } from "core/models/sale";
import { SaleService } from "core/api/sale/saleService";

const useValidatePayment = () => {
  const {
    formik,
    paymentInfo,
    tempCupom,
    setIsLoading,
    setResponse,
    setActiveStep,
    activeStep,
  } = useSaleFormContext();
  const { saleTypeByUrl, token, cupomId } = useSaleUrl();

  const validatePayment = async () => {
    setIsLoading(true);

    let clearedClient: TNewClientBodyRequest = {
      name: removeExtraSpaces(formik.values.name),
      cpforcnpj: removeNonNumeric(formik.values.cpforcnpj) ?? "",
      email: removeExtraSpaces(formik.values.email),
      password: removeExtraSpaces(formik.values.password),
      city: removeExtraSpaces(formik.values.city),
      uf: removeExtraSpaces(formik.values.uf),
      cep: removeNonNumeric(formik.values.cep) ?? "",
      address: removeExtraSpaces(formik.values.address),
      neighborhood: removeExtraSpaces(formik.values.neighborhood),
      telephone: removeNonNumeric(formik.values.telephone) ?? "",
      confirmPassword: removeExtraSpaces(formik.values.confirmPassword),
      cameThrough: removeExtraSpaces(formik.values.cameThrough),
    };

    let saleInfos: TSaleBodyRequest = {
      client: clearedClient,
      contract: true,
      tokenSales: token ?? "",
      cameThrough: formik.values.cameThrough,
      isFees: paymentInfo.isFees,
      typePayment: paymentInfo.typePayment,
      installments: paymentInfo.installments,
      couponId: cupomId && cupomId !== 0 ? cupomId : Number(tempCupom?.id),
      typeSales: saleTypeByUrl as Compra,
    };

    await SaleService.submitSale(saleInfos)
      .then((resp) => {
        setResponse(resp.data);
        setIsLoading(false);
        setActiveStep(activeStep + 1);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return { validatePayment };
};

export default useValidatePayment;
